// SearchOverlay.js
import React, { useState, useEffect } from "react";
import { Dialog, InputGroup, Button } from "@blueprintjs/core";
import { Box } from "@mui/material";
import { IconNames } from "@blueprintjs/icons";
import { DataGrid } from "@mui/x-data-grid";
import authApi from "../api/authApi";

const SearchOverlay = ({
  isOpen,
  onClose,
  selectedUserIds,
  setSelectedUserIds,
  shownUsers,
  setShownUsers,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState("full_name");

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      return;
    }

    try {
      const response = await authApi.post("/api/search/search_users", {
        query: searchTerm,
        search_type: searchType,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  const columns = [
    {
      field: "selection",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Button
          minimal
          icon={
            selectedUserIds.includes(params.row.user_id)
              ? IconNames.TICK_CIRCLE
              : IconNames.ADD
          }
          intent={
            selectedUserIds.includes(params.row.user_id) ? "success" : "none"
          }
          onClick={() => handleSelectionChange(params.row.user_id)}
          style={{
            outline: "none",
            boxShadow: "none",
            transition: "transform 0.2s",
          }}
        />
      ),
    },
    { field: "user_id", headerName: "User ID", flex: 1 },
    { field: "full_name", headerName: "Name", flex: 1 },
    { field: "username", headerName: "Account Name", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1 },
  ];

  const handleSelectionChange = (userId) => {
    const selectedIndex = selectedUserIds.indexOf(userId);
    let newSelectedUserIds = [];

    if (selectedIndex === -1) {
      newSelectedUserIds = [...selectedUserIds, userId];
    } else {
      newSelectedUserIds = selectedUserIds.filter((id) => id !== userId);
    }

    setSelectedUserIds(newSelectedUserIds);

    // add them to shownUsers where they are not there already, just append them
    const newShownUsers = [];

    newSelectedUserIds.forEach((userId) => {
      if (!shownUsers.find((u) => u.user_id === userId)) {
        newShownUsers.push(userId);
      }
    });

    if (newShownUsers.length > 0) {
      setShownUsers([...shownUsers, ...newShownUsers]);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Search Users"
      className="search-overlay"
    >
      <Box
        sx={{
          // width: "50%",
          // height: "50%",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <div
          className="search-type-select"
          style={{
            display: "flex",
            marginBottom: "16px",
          }}
        >
          <Button
            active={searchType === "account_name"}
            onClick={() => setSearchType("account_name")}
          >
            Account Name
          </Button>
          <Button
            active={searchType === "full_name"}
            onClick={() => setSearchType("full_name")}
          >
            Name
          </Button>
          <Button
            active={searchType === "phone_number"}
            onClick={() => setSearchType("phone_number")}
          >
            Phone Number
          </Button>
          <Button
            active={searchType === "user_id"}
            onClick={() => setSearchType("user_id")}
          >
            User ID
          </Button>
        </div>
        <InputGroup
          leftIcon="search"
          placeholder={`Enter ${searchType.replace("_", " ")}`}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch();
          }}
          style={{ marginBottom: "16px" }}
        />
        <div className="search-results" style={{ flexGrow: 1 }}>
          <DataGrid
            rows={searchResults}
            columns={columns}
            pageSize={100}
            getRowId={(row) => row.user_id}
            sx={{
              backgroundColor: "white",
              color: "black",
              "& .MuiDataGrid-cell": {
                color: "black",
              },
              "& .MuiDataGrid-main": {
                height: "100%",
              },
            }}
            autoHeight
            headerHeight={56}
            rowHeight={52}
            // style={{ height: "100%" }}
          />
        </div>
      </Box>
    </Dialog>
  );
};

export default SearchOverlay;
