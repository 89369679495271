import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Tabs,
  Tab,
  Grid,
  Tooltip,
  Paper,
  Avatar,
  Divider,
  Card,
  CardContent,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import DeckGL from "@deck.gl/react";
import { Map } from "react-map-gl";
import { ScatterplotLayer } from "@deck.gl/layers";
import authApi from "../api/authApi";
import { Group, LocationOn, History } from "@mui/icons-material";
import { formatDistanceToNow, parseISO, format } from "date-fns";
import { Cell, Column, Table } from "@blueprintjs/table";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip as RechartsTooltip,
//   ResponsiveContainer,
// } from "recharts";

const ChannelPage = () => {
  const { channelId } = useParams();
  const [mapboxToken, setMapboxToken] = useState(null);
  const [channelInfo, setChannelInfo] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [scrapeHistory, setScrapeHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });

  const fetchChannelData = useCallback(async () => {
    try {
      const [infoResponse, participantsResponse, scrapeHistoryResponse] =
        await Promise.all([
          authApi.get(`/api/channel/info/${channelId}`),
          authApi.get(`/api/channel/participants/${channelId}`),
          authApi.get(`/api/channel/scrape_history/${channelId}`),
        ]);

      setChannelInfo(infoResponse.data);
      setParticipants(participantsResponse.data);
      setScrapeHistory(scrapeHistoryResponse.data);

      if (infoResponse.data.avg_latitude && infoResponse.data.avg_longitude) {
        setViewState((prev) => ({
          ...prev,
          latitude: infoResponse.data.avg_latitude,
          longitude: infoResponse.data.avg_longitude,
        }));
      }
    } catch (err) {
      setError("Failed to fetch channel data");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [channelId]);

  const fetchMapConfig = async () => {
    try {
      const response = await authApi.get("/config/map");
      setMapboxToken(response.data.mapbox_token);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchMapConfig();
  }, []);

  useEffect(() => {
    fetchChannelData();
  }, [fetchChannelData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = parseISO(timestamp);
    const relativeTime = formatDistanceToNow(date, { addSuffix: true });
    const exactTime = format(date, "yyyy-MM-dd HH:mm:ss");
    return { relativeTime, exactTime };
  };

  const participantsCellRenderer = (rowIndex) => (
    <Cell>{scrapeHistory[rowIndex].participants_count}</Cell>
  );

  const dateCellRenderer = (rowIndex) => (
    <Cell>
      {format(
        parseISO(scrapeHistory[rowIndex].scrape_timestamp),
        "yyyy-MM-dd HH:mm:ss"
      )}
    </Cell>
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const layer = new ScatterplotLayer({
    id: "channel-location",
    data: [
      {
        longitude: channelInfo.avg_longitude,
        latitude: channelInfo.avg_latitude,
      },
    ],
    getPosition: (d) => [d.longitude, d.latitude],
    getColor: [255, 0, 0],
    getRadius: 1000,
    opacity: 0.5,
    pickable: true,
    radiusMinPixels: 3,
    radiusMaxPixels: 30,
  });

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar
              src={`/api/telegram/channel_photo/${channelInfo.photo_id}`}
              alt={channelInfo.title}
              sx={{ width: 80, height: 80, mr: 2 }}
            />
            <Box>
              <Typography variant="h4" gutterBottom>
                {channelInfo.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                @{channelInfo.username || "N/A"}
              </Typography>
            </Box>
          </Box>
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Channel Info" />
            <Tab label="Location" />
            <Tab
              label={
                <Badge
                  badgeContent={participants.length}
                  max={99999}
                  color="primary"
                >
                  Participants
                </Badge>
              }
            />
            <Tab label="Channel History" />
          </Tabs>
        </Paper>

        {activeTab === 0 && channelInfo && (
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Channel Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Channel ID:</strong> {channelInfo.id}
                </Typography>
                {/* <Typography variant="body1">
                  <strong>Participants:</strong>{" "}
                  {channelInfo.participants_count}
                </Typography> */}
                <Typography variant="body1">
                  <strong>Created:</strong>{" "}
                  {formatTimestamp(channelInfo.date_created).relativeTime}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Is Broadcast:</strong>{" "}
                  {channelInfo.is_broadcast ? "Yes" : "No"}
                </Typography>
                <Typography variant="body1">
                  <strong>Is Verified:</strong>{" "}
                  {channelInfo.is_verified ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}

        {activeTab === 1 && (
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Channel Location
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ height: "500px", position: "relative" }}>
              <DeckGL
                initialViewState={viewState}
                controller={true}
                layers={[layer]}
              >
                <Map
                  mapboxAccessToken={mapboxToken}
                  mapStyle="mapbox://styles/mapbox/dark-v10"
                />
              </DeckGL>
            </Box>
          </Paper>
        )}

        {activeTab === 2 && (
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Participants
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              {participants.map((user) => (
                <ListItem
                  key={user.user_id}
                  button
                  component={Link}
                  to={`/user/${user.user_id}`}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={`/api/user_photo/${
                        user.user_id + "_" + user.photo_id + ".jpg"
                      }`}
                      alt={`${user.first_name} ${user.last_name || ""}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user.first_name} ${
                      user.last_name || ""
                    }`.trim()}
                    secondary={`@${user.username || "N/A"}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}

        {activeTab === 3 && (
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Channel History
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Table numRows={scrapeHistory.length}>
              <Column name="Date" cellRenderer={dateCellRenderer} />
              {/* <Column
                name="Participants"
                cellRenderer={participantsCellRenderer}
              /> */}
            </Table>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default ChannelPage;
