import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GrafanaPage = () => {
  const location = useLocation();

  useEffect(() => {
    const currentOrigin = window.location.origin;
    const grafanaPath = location.pathname.replace("/grafana", "");
    const grafanaUrl = `${currentOrigin}/grafana${grafanaPath}${location.search}`;
    window.location.href = grafanaUrl;
  }, [location]);

  return null;
};

export default GrafanaPage;
