// MapControls.js
import React, { useState, useEffect, useMemo, useRef } from "react";
import { ToggleButtonGroup, ToggleButton, Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import PolygonIcon from "@mui/icons-material/Pentagon";
import CircleIcon from "@mui/icons-material/RadioButtonUnchecked";
import PanToolIcon from "@mui/icons-material/PanTool";
import StraightenIcon from "@mui/icons-material/Straighten";
import HeatmapIcon from "@mui/icons-material/Thermostat";
import MapIcon from "@mui/icons-material/Map";
import PersonSearch from "@mui/icons-material/PersonSearch";
import ChatIcon from "@mui/icons-material/Chat";

const MapControls = React.memo(
  ({
    mode,
    // toggleDrawingMode,
    setMode,
    showHeatmap,
    setShowHeatmap,
    isHeatmapLoading,
    baseLayers,
    selectedBaseLayer,
    setSelectedBaseLayer,
    setIsSearchOverlayOpen,
    channelViewEnabled,
    setChannelViewEnabled,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
      console.log("Rendering MapControls");
    });

    const handleBasemapPickerClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleBasemapPickerClose = () => {
      setAnchorEl(null);
    };

    const handleBasemapChange = (layer) => {
      setSelectedBaseLayer(layer);
      handleBasemapPickerClose();
    };

    const isLightMode =
      selectedBaseLayer &&
      (selectedBaseLayer.includes("light") ||
        selectedBaseLayer.includes("outdoors"));

    return (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            paddingRight: "15px",
            paddingTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <ToggleButtonGroup
            exclusive
            orientation="vertical"
            variant="contained"
            aria-label="Drawing mode"
            style={{
              position: "absolute",
              transform: "scale(0.9)",
            }}
            value={mode}
          >
            <Tooltip title="Select mode" placement="right">
              <ToggleButton
                value="GeoJsonEditMode"
                onClick={() => setMode("GeoJsonEditMode")}
                aria-label="GeoJson Edit Mode"
              >
                <PanToolIcon
                  style={{ color: isLightMode ? "black" : "white" }}
                />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Draw a polygon" placement="right">
              <ToggleButton
                value="DrawPolygonMode"
                onClick={() => setMode("DrawPolygonMode")}
                aria-label="Draw Polygon Mode"
              >
                <PolygonIcon
                  style={{ color: isLightMode ? "black" : "white" }}
                />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Draw a circle" placement="right">
              <ToggleButton
                value="DrawCircleFromCenterMode"
                onClick={() => setMode("DrawCircleFromCenterMode")}
                aria-label="Draw Circle From Center Mode"
              >
                <CircleIcon
                  style={{ color: isLightMode ? "black" : "white" }}
                />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Measure distance" placement="right">
              <ToggleButton
                value="MeasureDistanceMode"
                onClick={() => setMode("MeasureDistanceMode")}
                aria-label="Measure Distance Mode"
              >
                <StraightenIcon
                  style={{ color: isLightMode ? "black" : "white" }}
                />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>

          <Tooltip title="Toggle coverage heatmap" placement="right">
            <ToggleButton
              value="showHeatmap"
              selected={showHeatmap}
              onChange={() => setShowHeatmap(!showHeatmap)}
              aria-label="Show Heatmap"
              style={{
                marginTop: "190px",
                transform: "scale(0.9)",
              }}
              disabled={isHeatmapLoading}
            >
              <HeatmapIcon style={{ color: isLightMode ? "black" : "white" }} />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Change Basemap Style" placement="right">
            <ToggleButton
              value="showBasemapPicker"
              onClick={handleBasemapPickerClick}
              aria-label="Change Basemap Style"
              style={{
                marginTop: "8px",
                transform: "scale(0.9)",
              }}
            >
              <MapIcon style={{ color: isLightMode ? "black" : "white" }} />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Search for a User" placement="right">
            <ToggleButton
              value="searchTelegramUser"
              onClick={() => setIsSearchOverlayOpen(true)}
              aria-label="Search for a User"
              color="primary"
              style={{
                marginTop: "8px",
                transform: "scale(0.9)",
              }}
            >
              <PersonSearch color="primary" />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Toggle Channel View" placement="right">
            <ToggleButton
              value="channelView"
              selected={channelViewEnabled}
              disabled={true}
              onChange={() => setChannelViewEnabled(!channelViewEnabled)}
              aria-label="Toggle Channel View"
              style={{
                marginTop: "8px",
                transform: "scale(0.9)",
              }}
            >
              <ChatIcon style={{ color: "green" }} />
            </ToggleButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleBasemapPickerClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {baseLayers.map((layer) => (
              <MenuItem
                key={layer.value}
                selected={selectedBaseLayer === layer.value}
                onClick={() => handleBasemapChange(layer.value)}
              >
                {layer.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </>
    );
  }
);

export default MapControls;
