import React from "react";
import { Button, Card, Elevation, H3, Intent } from "@blueprintjs/core";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.log("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#293742",
          }}
        >
          <Card
            elevation={Elevation.FOUR}
            style={{ maxWidth: "400px", padding: "20px", textAlign: "center" }}
          >
            <H3 style={{ marginBottom: "20px", color: "#F5F8FA" }}>
              Oops! Something went wrong.
            </H3>
            <p style={{ marginBottom: "20px", color: "#CED9E0" }}>
              We're sorry, but an error occurred while rendering this page. Our
              team has been notified and we're working to fix it.
            </p>
            {this.state.error && (
              <pre
                style={{
                  textAlign: "left",
                  backgroundColor: "#30404D",
                  padding: "10px",
                  borderRadius: "4px",
                  color: "#F5F8FA",
                  overflowX: "auto",
                  marginBottom: "20px",
                }}
              >
                {this.state.error.toString()}
              </pre>
            )}
            <Button
              intent={Intent.PRIMARY}
              onClick={() => window.location.reload()}
              style={{ marginRight: "10px" }}
            >
              Reload Page
            </Button>
            <Button
              intent={Intent.SUCCESS}
              onClick={() => (window.location.href = "/")}
            >
              Go to Home
            </Button>
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
