import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Grid,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDropzone } from "react-dropzone";
import authApi from "../api/authApi";

const TelegramSessionsPage = () => {
  const [sessions, setSessions] = useState([]);
  const [files, setFiles] = useState([]);
  const [proxySettings, setProxySettings] = useState({
    type: "socks5",
    addr: "gate.smartproxy.com",
    port: "7000",
    username: "user-exodia-country-us",
    password: "",
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await authApi.get("/api/telegram-sessions");
      setSessions(response.data);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  const handleStringifySessions = async () => {
    try {
      const response = await authApi.post("/api/telegram-sessions/stringify");
      setSnackbar({ open: true, message: response.data.message });
    } catch (error) {
      console.error("Error stringifying sessions:", error);
      setSnackbar({ open: true, message: "Error stringifying sessions" });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".session",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    multiple: true,
  });

  const handleProxyChange = (event) => {
    setProxySettings({
      ...proxySettings,
      [event.target.name]: event.target.value,
    });
  };

  const getProxyEndpoint = () => {
    const { type, username, password, addr, port } = proxySettings;
    const maskedPassword = password ? "*".repeat(password.length) : "";
    return `${type}://${username}:${maskedPassword}@${addr}:${port}`;
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("session_files", file);
    });

    const proxyData = { ...proxySettings, endpoint: getProxyEndpoint() };
    Object.keys(proxyData).forEach((key) => {
      formData.append(key, proxyData[key]);
    });

    try {
      const response = await authApi.post(
        "/api/telegram-sessions/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setSnackbar({ open: true, message: response.data.message });
      fetchSessions();
      setFiles([]);
    } catch (error) {
      console.error("Error uploading sessions:", error);
      setSnackbar({ open: true, message: "Error uploading sessions" });
    }
  };

  const handleDelete = async (id) => {
    try {
      await authApi.delete(`/api/telegram-sessions/${id}`);
      setSnackbar({ open: true, message: "Session deleted successfully" });
      fetchSessions(); // Refresh the sessions list
    } catch (error) {
      console.error("Error deleting session:", error);
      setSnackbar({ open: true, message: "Error deleting session" });
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Upload New Sessions
      </Typography>

      <form onSubmit={handleSubmit}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #ccc",
              padding: 20,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            <p>Drag and drop .session files here, or click to select files</p>
          </div>
          {files.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1" gutterBottom>
                Selected files:
              </Typography>
              <List dense>
                {files.map((file, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveFile(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Paper>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Proxy Type"
              name="type"
              value={proxySettings.type}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Proxy Address"
              name="addr"
              value={proxySettings.addr}
              onChange={handleProxyChange}
              InputProps={{
                placeholder: "gate.smartproxy.com",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Proxy Port"
              name="port"
              type="number"
              value={proxySettings.port}
              onChange={handleProxyChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Proxy Username"
              name="username"
              value={proxySettings.username}
              onChange={handleProxyChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Proxy Password"
              name="password"
              type="password"
              value={proxySettings.password}
              onChange={handleProxyChange}
            />
          </Grid>
        </Grid>

        <TextField
          fullWidth
          label="Proxy Endpoint"
          value={getProxyEndpoint()}
          disabled
          sx={{ mt: 2 }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button type="submit" variant="contained" color="primary">
            Upload Sessions
          </Button>
        </Box>
      </form>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        mb={2}
      >
        <Typography variant="h4">Telegram Sessions</Typography>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<RefreshIcon />}
          onClick={handleStringifySessions}
          disabled={true}
        >
          Stringify All Sessions
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Session</TableCell>
              <TableCell>Proxy Type</TableCell>
              <TableCell>Proxy Address</TableCell>
              <TableCell>Proxy Port</TableCell>
              <TableCell>Banned</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session) => (
              <TableRow key={session.id}>
                <TableCell>{session.session}</TableCell>
                <TableCell>{session.proxy_type}</TableCell>
                <TableCell>{session.proxy_addr}</TableCell>
                <TableCell>{session.proxy_port}</TableCell>
                <TableCell>{session.banned ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDelete(session.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default TelegramSessionsPage;
