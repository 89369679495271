// MapPage.js
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";

import { Box, IconButton, Tooltip } from "@mui/material";
import { IconNames } from "@blueprintjs/icons";
import ObjectTable from "../components/ObjectTable";
// import ObjectTable from "../components/ObjectTableTest";
import MapComponent from "../components/MapComponent";

// Timeline Sliders
import SliderWithBackdropV2 from "../components/time-slider/SliderWithBackdropV2.js";

import SearchOverlay from "../components/SearchOverlay.js";

import { ChevronRight, ChevronLeft } from "@mui/icons-material";

// utils
import { createCirclePolygon } from "../utils/geoUtils.js";
import { bbox } from "@turf/turf";

// contexts
import { ToasterContext } from "./../contexts/ToasterContext.js";

import { WebMercatorViewport, FlyToInterpolator } from "@deck.gl/core";

import "react-resizable/css/styles.css";

// api
import authApi from "../api/authApi";

const NULL_FEATURE_COLLECTION = { type: "FeatureCollection", features: [] };

const MappingPage = () => {
  // all selected users from object table onto map
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  // single selected user for object view
  const [selectedMapUser, setSelectedMapUser] = useState(null);
  const [shownUsers, setShownUsers] = useState([]); // State to store the users that are shown in the table
  const [isObjectTableVisible, setObjectTableIsVisible] = useState(false); // State to toggle visibility
  const [isLoadingObjectTable, setIsLoadingObjectTable] = useState(false); // State to toggle loading state
  const [triangulatedUsers, setTriangulatedUsers] = useState([]); // State to store the triangulated users
  const [userData, setUserData] = useState([]);
  const [userTags, setUserTags] = useState({});
  const [trackData, setTrackData] = useState(null);
  const [circleData, setCircleData] = useState(NULL_FEATURE_COLLECTION);
  const [layers, setLayers] = useState([]);
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);
  // timestamps
  const [timestampSliderEnabled, setTimestampSliderEnabled] = useState(false);
  const [timestampSelectionStart, setTimestampSelectionStart] = useState(null);
  const [timestampSelectionEnd, setTimestampSelectionEnd] = useState(null);
  const [bucketRange, setBucketRange] = useState(6); // bucket range in hours to group triangulated points
  const [includeNullTimestampsInFilter, setIncludeNullTimestampsInFilter] =
    useState(false);

  const [flyToState, setFlyToState] = useState(null);

  const deckRef = useRef(null);

  useEffect(() => {
    console.log("MappingPage rendered");
  });

  const handleFlyTo = (newViewState) => {
    setFlyToState(newViewState);
  };

  const circleLayer = layers.find((layer) => layer.id === "telegram-circles");
  const triangulatedLayer = layers.find(
    (layer) => layer.id === "telegram-triangulated"
  );

  const onSelectionChange = (userId) => {
    const selectedIndex = selectedUserIds.indexOf(userId);
    let newSelectedUserIds = [];

    if (selectedIndex === -1) {
      newSelectedUserIds = [...selectedUserIds, userId];
    } else {
      newSelectedUserIds = selectedUserIds.filter((id) => id !== userId);
    }

    setSelectedUserIds(newSelectedUserIds);
  };

  const zoomToUser = useCallback(
    (user_id) => {
      // Get user's points from circleLayer and triangulatedLayer
      const userFeatures =
        circleLayer?.props.data.filter(
          (polygon) => polygon.properties.user_id === user_id
        ) || [];

      const userTriangulatedFeatures =
        triangulatedLayer?.props.data.features.filter(
          (feature) => feature.properties.user_id === user_id
        ) || [];

      const allUserFeatures = [...userFeatures, ...userTriangulatedFeatures];

      console.log(allUserFeatures);

      if (allUserFeatures.length === 0) {
        console.log("No user features available for zooming.");
        return;
      }

      const userBoundingBox = bbox({
        type: "FeatureCollection",
        features: allUserFeatures,
      });

      const [minLng, minLat, maxLng, maxLat] = userBoundingBox;

      // Create a WebMercatorViewport instance
      const viewport = new WebMercatorViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      // Get the bounding box in Web Mercator coordinates
      const { longitude, latitude, zoom } = viewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 20, // Add some padding around the bounding box
        }
      );

      const newViewState = {
        longitude,
        latitude,
        zoom,
        pitch: 0,
        bearing: 0,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
      };

      console.log("Setting view state", newViewState);
      // setViewState(newViewState);
      setFlyToState(newViewState);
    },
    [circleLayer, triangulatedLayer, setFlyToState]
  );

  const [timestamp, setTimestamp] = useState(Date.now());
  const [startTimestamp, setStartTimestamp] = useState(
    new Date("2024-04-22").getTime()
  );
  const [endTimestamp, setEndTimestamp] = useState(Date.now());

  const toaster = useContext(ToasterContext);

  const showToast = (message, intent, icon) => {
    toaster.current?.show({
      message,
      intent,
      icon,
    });
  };

  useEffect(() => {
    const fetchUserTracks = async () => {
      if (selectedUserIds.length === 0) {
        setTrackData(null);
        setCircleData(NULL_FEATURE_COLLECTION);
        return;
      }

      try {
        const response = await authApi.post("/api/search/get_tracks", {
          user_ids: selectedUserIds,
        });
        const { tracks } = response.data;

        const trackFeatures = [];
        const circleFeatures = [];

        tracks.forEach((track) => {
          track.track_points.forEach((point) => {
            const { latitude, longitude, radius, hit_type, ...properties } =
              point;
            const feature = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [longitude, latitude],
              },
              properties: { ...properties, hit_type },
            };
            trackFeatures.push(feature);

            // Only create circle features for hit_type 'person'
            // if (hit_type === "person") {
            const circlePolygon = createCirclePolygon(
              longitude,
              latitude,
              radius
            );
            const circleFeature = {
              type: "Feature",
              geometry: circlePolygon,
              properties: { ...properties, hit_type },
            };
            circleFeatures.push(circleFeature);
            // }
          });
        });

        const trackGeojson = {
          type: "FeatureCollection",
          features: trackFeatures,
        };
        const circleGeojson = {
          type: "FeatureCollection",
          features: circleFeatures,
        };
        setTrackData(trackGeojson);
        setCircleData(circleGeojson);
      } catch (error) {
        console.error("Error fetching user tracks:", error);
        showToast("Error fetching user tracks", "danger", IconNames.ERROR);
      }
    };

    fetchUserTracks();
  }, [selectedUserIds]);

  const fetchUserTags = async (userIds) => {
    try {
      const response = await authApi.post("/api/telegram/user_tags_many", {
        user_ids: userIds,
      });
      setUserTags(response.data);
    } catch (error) {
      console.error("Error fetching user tags:", error);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      setDataFetched(false);
      try {
        const response = await authApi.post("/api/telegram/user_info_many", {
          user_ids: shownUsers,
        });
        const userInfoList = response.data;
        setUserData(userInfoList);

        // Fetch tags after setting user data
        fetchUserTags(shownUsers);
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setDataFetched(true);
        setTimeout(() => {
          setIsLoadingObjectTable(false);
        }, 100);
      }
    };

    fetchUserInfo();
  }, [shownUsers, setIsLoadingObjectTable]);

  useEffect(() => {
    const fetchEarliestTimestamp = async () => {
      console.log("Fetching earliest timestamp");
      try {
        const response = await authApi.post(
          "/api/telegram/earliest_timestamp",
          {
            user_ids: selectedUserIds,
          }
        );
        console.log("Response", response.data);
        const earliestTimestamp = response.data.earliest_timestamp;
        if (earliestTimestamp) {
          console.log("earliestTimestamp", earliestTimestamp);
          setStartTimestamp(new Date(earliestTimestamp).getTime());
        }
      } catch (error) {
        console.error("Error fetching earliest timestamp:", error);
      }
    };

    if (selectedUserIds.length > 0) {
      fetchEarliestTimestamp();
    }
  }, [selectedUserIds]);

  const handleObjectTableClose = useCallback(() => {
    setObjectTableIsVisible(false);
  }, []);

  const memoizedMapComponent = useMemo(
    () => (
      <MapComponent
        selectedUserIds={selectedUserIds}
        setSelectedUserIds={setSelectedUserIds}
        shownUsers={shownUsers}
        setShownUsers={setShownUsers}
        isLoadingObjectTable={isLoadingObjectTable}
        setIsLoadingObjectTable={setIsLoadingObjectTable}
        isObjectTableVisible={isObjectTableVisible}
        setObjectTableIsVisible={setObjectTableIsVisible}
        triangulatedUsers={triangulatedUsers}
        setTriangulatedUsers={setTriangulatedUsers}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        startTimestamp={startTimestamp}
        setStartTimestamp={setStartTimestamp}
        endTimestamp={endTimestamp}
        setEndTimestamp={setEndTimestamp}
        trackData={trackData}
        setTrackData={setTrackData}
        circleData={circleData}
        setCircleData={setCircleData}
        showToast={showToast}
        isSearchOverlayOpen={isSearchOverlayOpen}
        setIsSearchOverlayOpen={setIsSearchOverlayOpen}
        zoomToUser={zoomToUser}
        layers={layers}
        setLayers={setLayers}
        deckRef={deckRef}
        flyToState={flyToState}
        onFlyToComplete={() => setFlyToState(null)}
        timestampSliderEnabled={timestampSliderEnabled}
        setTimestampSliderEnabled={setTimestampSliderEnabled}
        selectedMapUser={selectedMapUser}
        setSelectedMapUser={setSelectedMapUser}
        timestampSelectedStart={timestampSelectionStart}
        timestampSelectedEnd={timestampSelectionEnd}
        includeNullTimestampsInFilter={includeNullTimestampsInFilter}
        bucketRange={bucketRange}
        setBucketRange={setBucketRange}
        userTags={userTags}
      />
    ),
    [
      selectedUserIds,
      shownUsers,
      isLoadingObjectTable,
      isObjectTableVisible,
      triangulatedUsers,
      timestamp,
      startTimestamp,
      endTimestamp,
      trackData,
      circleData,
      isSearchOverlayOpen,
      layers,
      flyToState,
      timestampSliderEnabled,
      selectedMapUser,
      timestampSelectionStart,
      timestampSelectionEnd,
      includeNullTimestampsInFilter,
      bucketRange,
    ]
  );

  const memoizedSliderWithBackdrop = useMemo(
    () => (
      <SliderWithBackdropV2
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        startTimestampLimit={startTimestamp}
        endTimestampLimit={endTimestamp}
        showSlider={timestampSliderEnabled}
        setShowSlider={setTimestampSliderEnabled}
        isObjectTableVisible={isObjectTableVisible}
        circleData={circleData}
        selectedMapUser={selectedMapUser}
        selectionStart={timestampSelectionStart}
        setSelectionStart={setTimestampSelectionStart}
        selectionEnd={timestampSelectionEnd}
        setSelectionEnd={setTimestampSelectionEnd}
        includeNullTimestampsInFilter={includeNullTimestampsInFilter}
        setIncludeNullTimestampsInFilter={setIncludeNullTimestampsInFilter}
      />
    ),
    [
      timestamp,
      startTimestamp,
      endTimestamp,
      timestampSliderEnabled,
      isObjectTableVisible,
      circleData,
      selectedMapUser,
      timestampSelectionStart,
      timestampSelectionEnd,
      includeNullTimestampsInFilter,
    ]
  );

  const memoizedSearchOverlay = useMemo(
    () => (
      <SearchOverlay
        isOpen={isSearchOverlayOpen}
        onClose={() => setIsSearchOverlayOpen(false)}
        selectedUserIds={selectedUserIds}
        setSelectedUserIds={setSelectedUserIds}
        shownUsers={shownUsers}
        setShownUsers={setShownUsers}
      />
    ),
    [isSearchOverlayOpen, selectedUserIds, shownUsers]
  );

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          height: "calc(100vh - 50px)",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        {isObjectTableVisible && (
          <Box
            sx={{
              position: "absolute",
              left: "calc(2% + 20px)",
              top: "calc(5% + 20px)",
              bottom: "calc(5% + 20px)",
              zIndex: 3,
              width: "600px",
              height: "calc(95% - 20px)",
              transition: "transform 0.3s",
              transform: isObjectTableVisible
                ? "translateX(0)"
                : "translateX(100%)",
            }}
          >
            <ObjectTable
              userData={userData}
              userTags={userTags}
              isLoadingObjectTable={isLoadingObjectTable}
              selectedUserIds={selectedUserIds}
              onZoomToUser={zoomToUser}
              onClose={handleObjectTableClose}
              onSelectionChange={onSelectionChange}
              clearResults={() => {
                // remove users from userData that are not in selectedUserId
                console.log(userData);
                const updatedUserData = userData.filter((user) =>
                  selectedUserIds.includes(user.user_id)
                );
                setUserData(updatedUserData);
                const updatedShownUsers = shownUsers.filter((id) =>
                  selectedUserIds.includes(id)
                );
                setShownUsers(updatedShownUsers);
              }}
            />
          </Box>
        )}
        {!isObjectTableVisible && (
          <Box
            sx={{
              position: "absolute",
              left: "calc(2% + 20px)",
              top: "calc(5% + 20px)",
              zIndex: 4, // Increase the zIndex to ensure the button is on top
            }}
          >
            <Tooltip
              title={
                isObjectTableVisible
                  ? "Close Results Table"
                  : "Open Results Table"
              }
            >
              <IconButton
                size="small"
                onClick={() => setObjectTableIsVisible(!isObjectTableVisible)}
                style={{
                  color: "white",
                  backgroundColor: "#1E1E1E",
                  borderRadius: "50%",
                  padding: "8px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
                }}
              >
                {isObjectTableVisible ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box
          sx={{
            position: "relative",
            flexGrow: 1,
            height: "100%",
          }}
        >
          {memoizedMapComponent}
        </Box>
        {memoizedSearchOverlay}
        {memoizedSliderWithBackdrop}
      </Box>
    </div>
  );
};

export default MappingPage;
