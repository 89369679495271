import { ScatterplotLayer } from "@deck.gl/layers";

const PulseEffect = ({ coordinates }) => {
  const pulseLayer1 = new ScatterplotLayer({
    id: "pulse-layer-1",
    data: [{ coordinates }],
    getPosition: (d) => d.coordinates,
    getFillColor: [255, 0, 0, 0],
    getLineColor: [255, 0, 0],
    getRadius: 10000,
    stroked: true,
    lineWidthMinPixels: 2,
    pickable: false,
    autoHighlight: false,
    transitions: {
      getRadius: {
        type: "spring",
        duration: 3000,
        enter: () => [0],
        onStart: () => [10000],
        onEnd: () => [1000],
      },
      getLineColor: {
        type: "spring",
        duration: 3000,
        enter: () => [255, 0, 0, 0],
        onStart: () => [255, 0, 0, 255],
        onEnd: () => [255, 0, 0, 0],
      },
    },
  });

  //   const pulseLayer2 = new ScatterplotLayer({
  //     id: "pulse-layer-2",
  //     data: [{ coordinates }],
  //     getPosition: (d) => d.coordinates,
  //     getFillColor: [255, 0, 0, 0],
  //     getLineColor: [255, 0, 0],
  //     getRadius: 40000,
  //     stroked: true,
  //     lineWidthMinPixels: 2,
  //     pickable: false,
  //     autoHighlight: false,
  //     transitions: {
  //       getRadius: {
  //         type: "spring",
  //         delay: 1500, // Delay the start of the second pulse
  //         duration: 3000,
  //         startValue: () => [0],
  //         onStart: () => [40000],
  //         onEnd: () => [1000],
  //       },
  //       getLineColor: {
  //         type: "spring",
  //         delay: 1500,
  //         duration: 3000,
  //         startValue: () => [255, 0, 0, 0],
  //         onStart: () => [255, 0, 0, 200],
  //         onEnd: () => [255, 0, 0, 0],
  //       },
  //     },
  //   });

  //   return [pulseLayer1, pulseLayer2];

  return [pulseLayer1];
};

export default PulseEffect;
