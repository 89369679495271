import React, { useState, useEffect, useCallback, useMemo } from "react";
import DeckGL from "@deck.gl/react";
import { Map } from "react-map-gl";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import {
  DrawCircleFromCenterMode,
  DrawPolygonMode,
  ViewMode,
} from "@nebula.gl/edit-modes";

const ExperimentalPage = () => {
  const [mapboxToken, setMapboxToken] = useState(null);
  const [viewState, setViewState] = useState({
    longitude: 36.53,
    latitude: 50.54,
    zoom: 5,
  });
  const [selectedBaseLayer, setSelectedBaseLayer] = useState(
    "mapbox://styles/mapbox/dark-v10"
  );
  const [mode, setMode] = useState(() => ViewMode);
  const [features, setFeatures] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);

  useEffect(() => {
    setMapboxToken(
      "pk.eyJ1IjoibWF0dWxhYWs3IiwiYSI6ImNsdDRtazM3MzA0MDIybG1ub3BkNmlqY3QifQ.VefC5Gfmoo8eD4NbZFjWvg"
    );
  }, []);

  const onEdit = useCallback(({ updatedData }) => {
    setFeatures(updatedData);
  }, []);

  const onClick = useCallback(({ object }) => {
    if (object) {
      console.log("Clicked on feature", object);
      setSelectedFeatureIndex(object.index);
    } else {
      setSelectedFeatureIndex(null);
    }
  }, []);

  const onHover = useCallback((info) => {
    setHoverInfo(info.object ? info : null);
  }, []);

  const layer = useMemo(
    () =>
      new EditableGeoJsonLayer({
        id: "geojson-layer",
        data: features,
        mode,
        selectedFeatureIndexes: [selectedFeatureIndex],
        onEdit,
        pickable: true,
        autoHighlight: true,
        onClick,
        onHover,
        pickingRadius: 5,
        updateTriggers: {
          data: features,
          selectedFeatureIndexes: [selectedFeatureIndex],
        },
      }),
    [features, mode, selectedFeatureIndex, onEdit, onClick, onHover]
  );

  return (
    <div
      style={{ position: "relative", width: "100%", height: "100vh" }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div style={{ position: "absolute", top: 10, left: 10, zIndex: 1 }}>
        <button onClick={() => setMode(() => DrawPolygonMode)}>
          Draw Polygon
        </button>
        <button onClick={() => setMode(() => DrawCircleFromCenterMode)}>
          Draw Circle
        </button>
        <button onClick={() => setMode(() => ViewMode)}>View Mode</button>
      </div>
      {hoverInfo && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            left: hoverInfo.x,
            top: hoverInfo.y,
          }}
        >
          Hovering over feature {hoverInfo.index}
        </div>
      )}
      <DeckGL
        viewState={viewState}
        onViewStateChange={({ viewState }) => setViewState(viewState)}
        controller={true}
        layers={[layer]}
        getCursor={({ isDragging }) => (isDragging ? "grabbing" : "crosshair")}
      >
        <Map mapboxAccessToken={mapboxToken} mapStyle={selectedBaseLayer} />
      </DeckGL>
    </div>
  );
};

export default ExperimentalPage;
