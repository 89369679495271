import React, { useState, useContext, useCallback } from "react";
import {
  Button,
  Card,
  Elevation,
  FormGroup,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import { Box, Typography, Grid } from "@mui/material";
import authApi from "../api/authApi";
import { ToasterContext } from "../contexts/ToasterContext";

const AdminChannelCrawlPage = () => {
  const [channelId, setChannelId] = useState("");
  const [maxDepth, setMaxDepth] = useState(null);
  const [loading, setLoading] = useState(false);
  const toaster = useContext(ToasterContext);

  const handleStartCrawl = useCallback(async () => {
    if (!channelId) {
      toaster.current.show({
        message: "Channel ID is required",
        intent: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      const response = await authApi.post("/api/channel/start_crawl", {
        channel_id: channelId,
        max_depth: maxDepth,
      });

      toaster.current.show({
        message: `Crawl started successfully. Task ID: ${response.data.task_id}`,
        intent: "success",
      });

      // Reset form after successful submission
      setChannelId("");
      setMaxDepth(null);
    } catch (error) {
      console.error("Error starting crawl:", error);
      toaster.current.show({
        message: `Error starting crawl: ${
          error.response?.data?.error || error.message
        }`,
        intent: "danger",
      });
    } finally {
      setLoading(false);
    }
  }, [channelId, maxDepth, toaster]);

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Start Channel Crawl
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card elevation={Elevation.TWO}>
            <FormGroup
              label="Channel ID"
              labelFor="channel-id-input"
              labelInfo="(required)"
            >
              <InputGroup
                id="channel-id-input"
                placeholder="Enter channel ID"
                value={channelId}
                onChange={(e) => setChannelId(e.target.value)}
                disabled={loading}
              />
            </FormGroup>

            <FormGroup
              label="Max Depth"
              labelFor="max-depth-input"
              labelInfo="(optional)"
            >
              <NumericInput
                id="max-depth-input"
                placeholder="Enter max depth"
                value={maxDepth}
                onValueChange={(value) => setMaxDepth(value)}
                min={1}
                disabled={loading}
              />
            </FormGroup>

            <Button
              intent="primary"
              text={loading ? "Starting Crawl..." : "Start Crawl"}
              onClick={handleStartCrawl}
              disabled={loading}
              style={{ marginTop: "20px" }}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminChannelCrawlPage;
