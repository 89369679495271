import React, { useState, useEffect, useContext, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ToasterContext } from "../contexts/ToasterContext";
import {
  Button,
  FormGroup,
  InputGroup,
  FileInput,
  Switch,
} from "@blueprintjs/core";
import { Box, Paper } from "@mui/material";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { WebMercatorViewport } from "@deck.gl/core";
import { Map } from "react-map-gl";
import bbox from "@turf/bbox";
import authApi from "../api/authApi";

const CreateLocationRequestPage = () => {
  const [file, setFile] = useState(null);
  const [requestNameBase, setRequestNameBase] = useState("Example Title");
  const [gridSize, setGridSize] = useState("5000");
  const [inRotation, setInRotation] = useState(false);
  const toaster = useContext(ToasterContext);

  const requestName = `${requestNameBase} (${gridSize}m)`;

  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 1,
  });
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [mapboxToken, setMapboxToken] = useState("YOUR_MAPBOX_TOKEN");

  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload({ target: { files: acceptedFiles } });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    // Test temp
    setMapboxToken(
      "pk.eyJ1IjoibWF0dWxhYWs3IiwiYSI6ImNsdDRtazM3MzA0MDIybG1ub3BkNmlqY3QifQ.VefC5Gfmoo8eD4NbZFjWvg"
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("geojson", file);
    formData.append("request_name", requestName);
    formData.append("grid_size_meters", gridSize);
    formData.append("in_rotation", inRotation);

    try {
      const response = await authApi.post(
        "/api/location_request/create_with_geojson",
        formData
      );
      console.log("Location Request created successfully", response.data);
      toaster.current.show({
        message: "Location Request created successfully",
        intent: "success",
      });
      // Clear form or navigate to a different page
    } catch (error) {
      console.error("Error creating location request:", error);
      toaster.current.show({
        message: "Error creating location request",
        intent: "danger",
      });
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    // Check file extension
    if (
      !(
        file.name.toLowerCase().endsWith(".geojson") |
        file.name.toLowerCase().endsWith(".json")
      )
    ) {
      toaster.current.show({
        message: "Please upload a .geojson or .json file",
        intent: "warning",
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        console.log("Raw file content:", event.target.result);
        const parsedGeoJson = JSON.parse(event.target.result);
        console.log("Parsed GeoJSON:", parsedGeoJson);

        // Basic GeoJSON structure validation
        if (!parsedGeoJson.type || !parsedGeoJson.features) {
          throw new Error("Invalid GeoJSON structure");
        }

        setFile(file);
        setGeoJsonData(parsedGeoJson);
      } catch (error) {
        console.error("Error parsing GeoJSON:", error);
        toaster.current.show({
          message: `Invalid GeoJSON file: ${error.message}`,
          intent: "danger",
        });
      }
    };
    reader.readAsText(file, "UTF-8");
  };

  const zoomToGeoJSON = (geoJson) => {
    const [minLng, minLat, maxLng, maxLat] = bbox(geoJson);
    const viewport = new WebMercatorViewport(viewState);
    const { longitude, latitude, zoom } = viewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 40 }
    );

    setViewState({
      ...viewState,
      longitude,
      latitude,
      zoom,
      transitionDuration: 1000,
    });
  };

  const layers = [
    new GeoJsonLayer({
      id: "geojson-layer",
      data: geoJsonData,
      filled: true,
      pointRadiusMinPixels: 2,
      opacity: 0.4,
      pointRadiusScale: 2000,
      getPointRadius: 1,
      getFillColor: [255, 70, 30, 180],
      getLineColor: [0, 0, 0],
      pickable: true,
    }),
  ];
  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      <DeckGL
        viewState={viewState}
        onViewStateChange={({ viewState }) => setViewState(viewState)}
        controller={true}
        layers={layers}
      >
        <Map
          mapStyle="mapbox://styles/mapbox/dark-v10"
          mapboxAccessToken={mapboxToken}
        />
      </DeckGL>
      <Box position="absolute" top={20} left={20} zIndex={1} width="400px">
        <Paper elevation={3} style={{ padding: "20px" }}>
          <form onSubmit={handleSubmit}>
            <FormGroup label="Request Name" labelFor="request-name">
              <InputGroup
                id="request-name"
                value={requestNameBase}
                onChange={(e) => setRequestNameBase(e.target.value)}
                placeholder="Example Title"
              />
              <div
                style={{ marginTop: "5px", fontSize: "0.9em", color: "#666" }}
              >
                Full name: {requestName}
              </div>
            </FormGroup>
            <FormGroup label="Grid Size (meters)" labelFor="grid-size">
              <InputGroup
                id="grid-size"
                type="number"
                value={gridSize}
                onChange={(e) => setGridSize(e.target.value)}
              />
            </FormGroup>
            <FormGroup label="GeoJSON File" labelFor="geojson-file">
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #ccc",
                  padding: "20px",
                  marginBottom: "10px",
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here ...</p>
                ) : (
                  <p>Drag and drop a GeoJSON file here, or click to select</p>
                )}
              </div>
              {file && <p>Selected file: {file.name}</p>}
            </FormGroup>
            <FormGroup label="Add to Rotation" inline>
              <Switch
                checked={inRotation}
                onChange={(e) => setInRotation(e.target.checked)}
              />
            </FormGroup>
            <Button
              type="submit"
              intent="primary"
              text="Create Location Request"
            />
          </form>
        </Paper>
      </Box>
    </div>
  );
};

export default CreateLocationRequestPage;
