import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Oops! Page not found
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you are looking for might have been removed or is temporarily
        unavailable.
      </Typography>
      <Button
        component={Link}
        to="/globe"
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: "#1e88e5", // A darker blue
          color: "#ffffff", // White text
          "&:hover": {
            backgroundColor: "#1565c0", // Even darker blue on hover
          },
        }}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
