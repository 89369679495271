import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Button as BlueprintButton, Switch } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Paper, Tooltip, Button } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { ControlledHighlight } from "./ControlledHighlight";
import { useResizeObserver } from "./useResizeObserver";
import { getUserColour } from "../../utils/userColours";
import "../../../src/main.css";

import {
  XYPlot,
  VerticalRectSeries,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  Crosshair,
  Highlight,
  DiscreteColorLegend,
} from "react-vis";
import "../../../node_modules/react-vis/dist/style.css";
import { set } from "date-fns";

const SliderWithBackdropV2 = ({
  timestamp,
  setTimestamp,
  startTimestampLimit,
  endTimestampLimit,
  showSlider,
  isObjectTableVisible,
  setShowSlider,
  circleData = { features: [] },
  selectedMapUser,
  selectionStart,
  setSelectionStart,
  selectionEnd,
  setSelectionEnd,
  includeNullTimestampsInFilter,
  setIncludeNullTimestampsInFilter,
}) => {
  const [data, setData] = useState([]);
  const [onlyShowSelected, setOnlyShowSelected] = useState(false);
  const [groupedData, setGroupedData] = useState([]);
  const [sliderValue, setSliderValue] = useState(0);
  const [crosshairValues, setCrosshairValues] = useState([]);

  const [zoomLevel, setZoomLevel] = useState(1); // Default zoom level to weeks
  const [xDomain, setXDomain] = useState([
    new Date(startTimestampLimit),
    new Date(endTimestampLimit),
  ]);
  const [yDomain, setYDomain] = useState([0, 5]); // Adjust initial y domain as needed
  const [dimensions, setDimensions] = useState({ width: 700, height: 300 });

  const plotContainerRef = useRef();

  useEffect(() => {
    console.log("Rendering SliderWithBackdropV2");
  });

  const handleShowSliderToggle = useCallback(() => {
    setShowSlider(!showSlider);
  }, [showSlider, setShowSlider]);

  const handleIncludeNullTimestampsChange = useCallback(() => {
    setIncludeNullTimestampsInFilter(!includeNullTimestampsInFilter);
  }, [includeNullTimestampsInFilter, setIncludeNullTimestampsInFilter]);

  const handleOnlyShowSelectedChange = useCallback(() => {
    setOnlyShowSelected(!onlyShowSelected);
  }, [onlyShowSelected]);

  const userIds = useMemo(() => {
    return circleData.features
      ? [
          ...new Set(
            circleData.features.map((feature) => feature.properties.user_id)
          ),
        ]
      : [];
  }, [circleData]);

  const filteredUserIds = useMemo(() => {
    if (onlyShowSelected && selectedMapUser) {
      console.log("switching to selectedMapUser:", selectedMapUser);
      return [selectedMapUser];
    }
    return userIds;
  }, [onlyShowSelected, selectedMapUser, userIds]);

  const nameColors = useMemo(() => {
    return userIds.reduce((colors, userId) => {
      const [r, g, b] = getUserColour(userId);
      colors[userId] = `rgb(${r}, ${g}, ${b})`;
      return colors;
    }, {});
  }, [userIds]);

  const zoomScales = [
    1000 * 60 * 60, // hours
    1000 * 60 * 60 * 24, // days
    1000 * 60 * 60 * 24 * 7, // weeks
    1000 * 60 * 60 * 24 * 30, // months
  ];

  const loadData = () => {
    if (!circleData || !circleData.features) {
      setData([]);
      return;
    }

    setData(
      circleData.features
        .filter((feature) =>
          filteredUserIds.includes(feature.properties.user_id)
        )
        .map((feature) => ({
          x: feature.properties.search_timestamp,
          y: feature.properties.user_id,
        }))
    );
  };

  const updateDragState = (area) => {
    setSelectionStart(area && area.left);
    setSelectionEnd(area && area.right);
  };

  useEffect(() => {
    if (circleData.features && circleData.features.length > 0) {
      loadData();
    }
  }, [circleData, filteredUserIds, onlyShowSelected]);

  const getMaxYValue = (groupedData) => {
    return Math.max(
      ...groupedData.flatMap((group) =>
        group.items ? group.items.map((item) => item.y) : [0]
      )
    );
  };

  const aggregateAndStackData = (groups) => {
    const aggregate = {};

    groups.forEach((group) => {
      if (!group.items) return;
      group.items.forEach((item) => {
        if (!aggregate[item.x]) {
          aggregate[item.x] = [];
        }
        const existing = aggregate[item.x].find((i) => i.color === group.color);
        if (existing) {
          existing.y += item.y;
        } else {
          item.y0 = 0; // Initialize y0 for new stack
          aggregate[item.x].push({ ...item, color: group.color });
        }
      });
    });

    Object.keys(aggregate).forEach((x) => {
      let y0 = 0;
      aggregate[x] = aggregate[x].map((item) => {
        const stackedItem = { ...item, y0, y: y0 + item.y };
        y0 += item.y;
        return stackedItem;
      });
    });

    return Object.values(aggregate).flat();
  };

  useEffect(() => {
    const tempGroupedData = filteredUserIds.map((userId) => {
      const filteredItems = data.filter(
        (d) =>
          new Date(d.x) >= xDomain[0] &&
          new Date(d.x) <= xDomain[1] &&
          d.y === userId
      );

      const placeholder = [{ x0: xDomain[0], x: xDomain[1], y: 0 }];

      return {
        name: userId,
        color: nameColors[userId],
        items:
          filteredItems.length > 0
            ? bucketData(filteredItems, zoomScales[zoomLevel])
            : placeholder,
      };
    });

    // Aggregate and stack the grouped data
    const stackedData = aggregateAndStackData(tempGroupedData);

    const updatedGroupedData = [
      {
        name: "stacked",
        color: null,
        items: stackedData,
      },
    ];

    setGroupedData(updatedGroupedData);

    const maxYValue = getMaxYValue(updatedGroupedData);
    setYDomain([0, maxYValue]);
  }, [zoomLevel, xDomain, data, filteredUserIds, nameColors]);

  const bucketData = (items, bucketSize) => {
    const buckets = {};

    items.forEach((item) => {
      const bucketStart = Math.floor(item.x / bucketSize) * bucketSize;
      const bucketEnd = bucketStart + bucketSize;

      if (!buckets[bucketStart]) {
        buckets[bucketStart] = { x0: bucketStart, x: bucketEnd, y: 0 };
      }

      buckets[bucketStart].y += 1;
    });

    return Object.values(buckets);
  };

  const incrementZoom = () => {
    setZoomLevel((prevZoomLevel) => {
      const newZoomLevel = Math.min(prevZoomLevel + 1, zoomScales.length - 1);
      return newZoomLevel;
    });
  };

  const decrementZoom = () => {
    setZoomLevel((prevZoomLevel) => {
      const newZoomLevel = Math.max(prevZoomLevel - 1, 0);
      return newZoomLevel;
    });
  };

  const panLeft = () => {
    const [start, end] = xDomain;
    const span = end - start;
    const panAmount = span / 5; // 20%
    const newStart = new Date(start.getTime() - panAmount);
    const newEnd = new Date(end.getTime() - panAmount);

    // Ensure the new domain values are valid dates
    if (isNaN(newStart) || isNaN(newEnd)) return;
    setXDomain([newStart, newEnd]);
  };

  const panRight = () => {
    const [start, end] = xDomain;
    const span = end - start;
    const panAmount = span / 5; // 20%
    const newStart = new Date(start.getTime() + panAmount);
    const newEnd = new Date(end.getTime() + panAmount);

    // Ensure the new domain values are valid dates
    if (isNaN(newStart) || isNaN(newEnd)) return;
    setXDomain([newStart, newEnd]);
  };

  const getColor = (item, baseColor) => {
    if (selectionStart && selectionEnd) {
      return item.x0 >= selectionStart && item.x <= selectionEnd
        ? baseColor
        : "#d3d3d3";
    }
    return baseColor;
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          setDimensions({
            width: entry.contentRect.width - 40, // Adjust based on desired padding
            height: Math.min(entry.contentRect.height - 40, 600), // Adjust max height as needed
          });
        }
      }
    });

    if (plotContainerRef.current) {
      resizeObserver.observe(plotContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Paper
        elevation={3}
        style={{
          position: "absolute",
          bottom: showSlider ? "20px" : "0px",
          left: isObjectTableVisible ? "calc(50% + 300px)" : "50%",
          transform: "translateX(-50%)",
          borderRadius: "4px",
          padding: "16px",
          width: isObjectTableVisible ? "calc(100% - 750px)" : "80%",
          transition: "bottom 0.3s, left 0.3s, width 0.3s",
          backgroundColor: "rgb(36, 39, 48)",
          display: "flex",
          flexDirection: "column",
          overflow: "visible", // Change overflow to visible
          zIndex: 1, // Ensure proper z-index stacking (optional, if needed)
        }}
      >
        <div
          ref={plotContainerRef}
          className="plot-container"
          style={{ display: showSlider ? "block" : "none" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Switch
              checked={includeNullTimestampsInFilter}
              onChange={handleIncludeNullTimestampsChange}
              label="Include Null Timestamps"
              disabled={!(selectionStart && selectionEnd)}
              style={{
                color: "white",
                marginBottom: "10px",
                marginLeft: "5px",
              }}
            />
            <Switch
              checked={onlyShowSelected}
              onChange={handleOnlyShowSelectedChange}
              label="Only Show Selected"
              style={{
                color: "white",
                marginBottom: "10px",
                marginRight: "5px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <XYPlot
              height={dimensions.height}
              width={dimensions.width}
              xType="time"
              yType="linear"
              xDomain={xDomain}
              yDomain={yDomain}
              onMouseLeave={() => setCrosshairValues([])}
              className="rvis-xy-plot"
            >
              <XAxis />
              <YAxis />
              {groupedData &&
              groupedData.some(
                (group) => group.items && group.items.length > 0
              ) ? (
                groupedData.flatMap((group) => (
                  <VerticalRectSeries
                    key={group.name || group.items[0].color}
                    data={group.items.map((item) => ({
                      ...item,
                      color: getColor(item, group.color || item.color),
                    }))}
                    colorType="literal"
                    style={{ stroke: "none", fillOpacity: 1 }}
                  />
                ))
              ) : (
                <VerticalRectSeries
                  data={[
                    {
                      x0: new Date(startTimestampLimit),
                      x: new Date(endTimestampLimit),
                      y: 0,
                    },
                  ]} // Dummy data to ensure rendering
                  style={{ stroke: "none", fillOpacity: 0.1 }}
                />
              )}
              <Highlight
                color="#829AE3"
                area={
                  selectionStart && selectionEnd
                    ? [selectionStart, selectionEnd]
                    : null
                }
                drag
                enableY={false}
                onDrag={updateDragState}
                onDragEnd={updateDragState}
              />
              <Crosshair values={crosshairValues} />
            </XYPlot>
          </div>
          <DiscreteColorLegend
            orientation="horizontal"
            items={filteredUserIds.map((userId) => ({
              title: userId,
              color: nameColors[userId],
            }))}
            className="slider-legend-text"
          />
          <div style={{ padding: "0px 35px", marginTop: "-20px" }}></div>
        </div>
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            top: "-24px",
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "4px 4px 0 0",
            padding: "8px 16px",
            backgroundColor: "rgb(36, 39, 48)",
            color: "white",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            zIndex: 10, // Add zIndex to ensure it is above the other paper container
          }}
          onClick={handleShowSliderToggle}
        >
          {showSlider ? (
            <>
              <Tooltip title="Hide Timeline">
                <ExpandMore />
              </Tooltip>
            </>
          ) : (
            <>
              <ExpandLess style={{ marginRight: "8px" }} />
              <span>Show Timeline</span>
            </>
          )}
        </Paper>
      </Paper>
    </>
  );
};

export default SliderWithBackdropV2;
