import {
  GeoJsonLayer,
  PolygonLayer,
  IconLayer,
  ScatterplotLayer,
  TextLayer,
} from "@deck.gl/layers";

import { getUserColour } from "../utils/userColours";

const createChatIconCanvas = () => {
  const canvas = document.createElement("canvas");
  canvas.width = 64;
  canvas.height = 64;
  const ctx = canvas.getContext("2d");

  // Draw a simple chat bubble icon
  // nice 'success' green
  ctx.fillStyle = "#00FF00";
  ctx.beginPath();
  ctx.moveTo(5, 5);
  ctx.lineTo(59, 5);
  ctx.lineTo(59, 45);
  ctx.lineTo(35, 45);
  ctx.lineTo(25, 59);
  ctx.lineTo(15, 45);
  ctx.lineTo(5, 45);
  ctx.closePath();
  ctx.fill();

  return canvas;
};

const chatIconCanvas = createChatIconCanvas();
const chatIconUrl = chatIconCanvas.toDataURL();

export const createTelegramLayers = (
  trackData,
  circleData,
  triangulatedDataArray,
  selectedMapUser,
  setSelectedMapUser,
  isHovering,
  triangulatedUsers,
  setTriangulatedUsers,
  timestampSliderEnabled,
  selectedUserIds,
  timestampSelectionStart,
  timestampSelectionEnd,
  includeNullTimestampInFilter
) => {
  const layers = [];

  if (!circleData || !circleData.features) {
    return layers;
  }
  const filteringEnabled =
    timestampSliderEnabled && timestampSelectionStart && timestampSelectionEnd;

  const filteredCircleData = {
    type: "FeatureCollection",
    features: circleData.features.filter((feature) => {
      const timeCondition = filteringEnabled
        ? ((includeNullTimestampInFilter &&
            feature.properties.earliest_seen_timestamp === null) ||
            feature.properties.earliest_seen_timestamp <=
              timestampSelectionEnd) &&
          ((includeNullTimestampInFilter &&
            feature.properties.latest_seen_timestamp === null) ||
            feature.properties.latest_seen_timestamp >= timestampSelectionStart)
        : true;
      return (
        timeCondition &&
        selectedUserIds.includes(feature.properties.user_id) &&
        !triangulatedUsers.includes(feature.properties.user_id)
      );
    }),
  };

  layers.push(
    new PolygonLayer({
      id: "telegram-circles",
      data: filteredCircleData.features,
      getPolygon: (d) => d.geometry.coordinates,
      getFillColor: (d) => {
        const userId = d.properties.user_id;
        const colour = getUserColour(userId);
        return [...colour, 25];
      },
      getLineColor: (d) => {
        const userId = d.properties.user_id;
        const isSelected = selectedMapUser === userId;
        const colour = getUserColour(userId);
        return isSelected ? [255, 255, 255] : [...colour, 75];
      },
      lineWidthMinPixels: 2,
      pickable: true,
      autoHighlight: true,
      highlightColor: [255, 255, 255, 100],
      onClick: (info) => {
        if (info.object) {
          setSelectedMapUser(info.object.properties.user_id);
        }
      },
      onHover: (info) => {
        if (info.object) {
          info.object.hoverInfo = `User ID: ${info.object.properties.user_id}`;
        }
      },
      updateTriggers: {
        getLineColor: [selectedMapUser],
      },
    })
  );

  if (triangulatedDataArray && triangulatedDataArray.length > 0) {
    const filteredTriangulatedData = triangulatedDataArray.filter((feature) => {
      const timeCondition = filteringEnabled
        ? ((includeNullTimestampInFilter &&
            (feature.properties.earliest_timestamp === "no_timestamp" ||
              feature.properties.earliest_timestamp === null)) ||
            feature.properties.earliest_timestamp <= timestampSelectionEnd) &&
          ((includeNullTimestampInFilter &&
            (feature.properties.latest_timestamp === null ||
              feature.properties.latest_timestamp === "no_timestamp")) ||
            feature.properties.latest_timestamp >= timestampSelectionStart)
        : true;

      const userCondition =
        selectedUserIds.includes(feature.properties.user_id) &&
        triangulatedUsers.includes(feature.properties.user_id);

      return timeCondition && userCondition;
    });

    if (filteredTriangulatedData.length > 0) {
      layers.push(
        new GeoJsonLayer({
          id: "telegram-triangulated",
          data: {
            type: "FeatureCollection",
            features: filteredTriangulatedData.map((feature) => ({
              type: "Feature",
              geometry: {
                type: feature.type,
                coordinates: feature.coordinates,
              },
              properties: feature.properties,
            })),
          },
          getFillColor: (d) => {
            const userId = d.properties.user_id;
            const colour = getUserColour(userId);
            return [...colour, 50];
          },
          getLineColor: (d) => {
            const userId = d.properties.user_id;
            const isSelected = selectedMapUser === userId;
            const colour = getUserColour(userId);
            return isSelected ? [255, 255, 255] : [...colour, 75];
          },
          lineWidthMinPixels: 2,
          pickable: true,
          autoHighlight: true,
          highlightColor: [255, 255, 255, 100],
          onClick: (info) => {
            if (info.object) {
              setSelectedMapUser(info.object.properties.user_id);
            }
          },
          onHover: (info) => {
            if (info.object) {
              info.object.hoverInfo = `User ID: ${info.object.properties.user_id}`;
            }
          },
          updateTriggers: {
            getLineColor: [selectedMapUser],
          },
        })
      );
    } else {
      console.log("No triangulated data after filtering");
    }
  } else {
    console.log("No triangulated data to process");
  }

  return layers;
};
