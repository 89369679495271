import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Grid,
  Tooltip,
  Button,
  Chip,
  Avatar,
  Divider,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Person,
  Phone,
  AccessTime,
  Android,
  Warning,
  ReportProblem,
} from "@mui/icons-material";
import { getUserColour } from "../utils/userColours";
import authApi from "../api/authApi";
import { formatDistanceToNow, parseISO, format } from "date-fns";

const NewUserInfoCard = ({
  open,
  onClose,
  user,
  borderColor,
  onUserChange,
  userTags,
}) => {
  const [photos, setPhotos] = useState([]);
  const [mainPhotoIndex, setMainPhotoIndex] = useState(0);

  useEffect(() => {
    if (user && open) {
      fetchUserPhotos(user.user_id);
    }
  }, [user, open]);

  const fetchUserPhotos = async (userId) => {
    try {
      const response = await authApi.get(`/api/telegram/user_photos/${userId}`);
      setPhotos(response.data.photos);
      setMainPhotoIndex(0);
    } catch (error) {
      console.error("Error fetching user photos:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        setMainPhotoIndex((prevIndex) => Math.max(0, prevIndex - 1));
      } else if (event.key === "ArrowRight") {
        setMainPhotoIndex((prevIndex) =>
          Math.min(photos.length - 1, prevIndex + 1)
        );
      } else if (event.key === "ArrowUp") {
        onUserChange("prev");
      } else if (event.key === "ArrowDown") {
        onUserChange("next");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [photos, onUserChange]);

  if (!user) return null;

  const mainPhoto = photos[mainPhotoIndex];
  const calculatedBorderColor = borderColor || getUserColour(user.user_id);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return { relativeTime: "N/A", exactTime: "N/A" };
    const date = parseISO(timestamp);
    const relativeTime = formatDistanceToNow(date, { addSuffix: true });
    const exactTime = format(date, "yyyy-MM-dd HH:mm:ss");
    return { relativeTime, exactTime };
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#1e1e1e",
          color: "white",
          border: `2px solid ${calculatedBorderColor}`,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">User Details</Typography>
        <Box>
          <Tooltip title="Opens in new tab" arrow placement="bottom">
            <Button
              variant="outlined"
              startIcon={<OpenInNewIcon />}
              href={`/user/${user.user_id}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "white",
                borderColor: "white",
                marginRight: 2,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderColor: "white",
                },
              }}
            >
              Open User Page
            </Button>
          </Tooltip>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, backgroundColor: "#2e2e2e" }}>
              <Box display="flex" alignItems="center" mb={2}>
                <Avatar
                  src={user.photo_url}
                  alt={user.full_name}
                  sx={{ width: 80, height: 80, mr: 2 }}
                />
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {user.full_name}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    @{user.username || "N/A"}
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{ mb: 2, backgroundColor: "rgba(255,255,255,0.1)" }}
              />
              <Box display="flex" alignItems="center" mb={1}>
                <Person sx={{ mr: 1 }} />
                <Typography variant="body1">
                  <strong>User ID:</strong> {user.user_id}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Phone sx={{ mr: 1 }} />
                <Typography variant="body1">
                  <strong>Phone:</strong> {user.phone_number || "N/A"}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={1}>
                <AccessTime sx={{ mr: 1 }} />
                <Typography variant="body1">
                  <strong>Last Seen:</strong>{" "}
                  <Tooltip
                    title={
                      formatTimestamp(user.max_latest_seen_timestamp).exactTime
                    }
                  >
                    <span>
                      {
                        formatTimestamp(user.max_latest_seen_timestamp)
                          .relativeTime
                      }
                    </span>
                  </Tooltip>
                </Typography>
              </Box>
              {user.is_bot && (
                <Box display="flex" alignItems="center" mb={1}>
                  <Android sx={{ mr: 1, color: "warning.main" }} />
                  <Typography variant="body1" color="warning.main">
                    <strong>Bot Account</strong>
                  </Typography>
                </Box>
              )}
              {user.is_fake && (
                <Box display="flex" alignItems="center" mb={1}>
                  <Warning sx={{ mr: 1, color: "error.main" }} />
                  <Typography variant="body1" color="error.main">
                    <strong>Fake Account</strong>
                  </Typography>
                </Box>
              )}
              {user.is_scam && (
                <Box display="flex" alignItems="center" mb={1}>
                  <ReportProblem sx={{ mr: 1, color: "error.main" }} />
                  <Typography variant="body1" color="error.main">
                    <strong>Scam Account</strong>
                  </Typography>
                </Box>
              )}
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  <LocalOfferIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                  Tags
                </Typography>
                <Divider
                  sx={{ mb: 2, backgroundColor: "rgba(255,255,255,0.1)" }}
                />
                {userTags && userTags.length > 0 ? (
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {userTags.map((tag) => (
                      <Chip
                        key={tag.id}
                        label={tag.name}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{
                          color: "white",
                          borderColor: "white",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                        }}
                        title={tag.description}
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No tags assigned
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, backgroundColor: "#2e2e2e" }}>
              <Typography variant="h6" gutterBottom>
                User Photos
              </Typography>
              <Divider
                sx={{ mb: 2, backgroundColor: "rgba(255,255,255,0.1)" }}
              />
              {mainPhoto && (
                <Tooltip
                  title={`Scraped: ${new Date(
                    mainPhoto.time_scraped
                  ).toLocaleString()}`}
                  arrow
                >
                  <Box
                    component="img"
                    src={mainPhoto.photo_url}
                    alt={`Main user photo`}
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginBottom: 2,
                      borderRadius: "4px",
                    }}
                  />
                </Tooltip>
              )}
              <Grid container spacing={1} justifyContent="center">
                {photos.map((photo, index) => (
                  <Grid item key={photo.photo_id}>
                    <Tooltip
                      title={`Scraped: ${new Date(
                        photo.time_scraped
                      ).toLocaleString()}`}
                      arrow
                    >
                      <Box
                        component="img"
                        src={photo.photo_url}
                        alt={`User photo ${photo.photo_id}`}
                        onClick={() => setMainPhotoIndex(index)}
                        sx={{
                          width: 60,
                          height: 60,
                          objectFit: "cover",
                          borderRadius: "4px",
                          cursor: "pointer",
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          border:
                            index === mainPhotoIndex
                              ? "2px solid white"
                              : "none",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NewUserInfoCard;
