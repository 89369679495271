import { createContext, useContext, useState, useEffect } from "react";
import { IconNames } from "@blueprintjs/icons";
import axios from "axios";
import { ToasterContext } from "./../contexts/ToasterContext.js";

const AuthContext = createContext();
const UserInfoContext = createContext();

const UserInfoProvider = ({ children, user }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get("/auth/user_info", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        console.log("User info from response:", response.data);
        setUserInfo(response.data);
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [user]);

  return (
    <UserInfoContext.Provider value={{ userInfo, isLoading }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export const AuthProvider = ({ children }) => {
  const toaster = useContext(ToasterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setUser({ token });
    }
    setIsLoading(false);
  }, []);

  const login = async (token, refreshToken) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("refresh_token", refreshToken);
    setUser({ token });
    toaster.current?.show({
      message: "Logged in",
      intent: "success",
      icon: IconNames.LOG_IN,
    });
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("is_active");
    setUser(null);
    toaster.current?.show({
      message: "Logged out",
      intent: "success",
      icon: IconNames.LOG_OUT,
    });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isLoading }}>
      <UserInfoProvider user={user}>{children}</UserInfoProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const useUserInfo = () => {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }
  return context;
};
