import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import DeckGL, { FlyToInterpolator } from "deck.gl";
import bbox from "@turf/bbox";
import { debounce } from "lodash";
import { Map } from "react-map-gl";
import { WebMercatorViewport } from "@deck.gl/core";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import { ContextMenu, Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { CircularProgress, Tooltip } from "@mui/material";

// api requests
import authApi from "../api/authApi.js";

// Custom components and functions
import ContextMenuItems from "./ContextMenuItems";
import AdvancedSearchModal from "./AdvancedSearchModal";
import MapControls from "./MapControls";
import UserInfoPopup from "./UserInfoPopup.js";
import EditableFeatureContextMenu from "./EditableFeatureContextMenu";

import { forwardRef } from "react";

import {
  createEditableLayer,
  createPulsingEffectLayers,
  createHeatmapLayer,
  createChannelLayer,
  createHoverLayer,
  layersEqual,
} from "../layers/util-layers.js";

import { createTelegramLayers } from "./../layers/telegram-layers.js";

const MapComponent = forwardRef(
  (
    {
      selectedUserIds,
      setSelectedUserIds,
      shownUsers,
      setShownUsers,
      isLoadingObjectTable,
      setIsLoadingObjectTable,
      isObjectTableVisible,
      setObjectTableIsVisible,
      triangulatedUsers,
      setTriangulatedUsers,
      timestamp,
      setTimestamp,
      startTimestamp,
      setStartTimestamp,
      endTimestamp,
      setEndTimestamp,
      trackData,
      setTrackData,
      circleData,
      setCircleData,
      showToast,
      isSearchOverlayOpen,
      setIsSearchOverlayOpen,
      zoomToUser,
      layers,
      setLayers,
      deckRef,
      flyToState,
      onFlyToComplete,
      timestampSliderEnabled,
      setTimestampSliderEnabled,
      selectedMapUser,
      setSelectedMapUser,
      timestampSelectedStart,
      timestampSelectedEnd,
      includeNullTimestampInFilter,
      bucketRange,
      setBucketRange,
      userTags,
    },
    ref
  ) => {
    const [viewState, setViewState] = useState({});
    const previousViewStateRef = useRef();

    // channel variables
    const [channelViewEnabled, setChannelViewEnabled] = useState(false);
    const [showChannels, setShowChannels] = useState(false);
    const [channelData, setChannelData] = useState([]);
    const [channelZoomThreshold, setChannelZoomThreshold] = useState(8); // Adjust this value as needed
    const [isLoadingChannels, setIsLoadingChannels] = useState(false);
    const fetchChannelData = useCallback(
      debounce(async (viewport) => {
        // console.log("fetchChannelData called with viewport:", viewport);
        // console.log("channelViewEnabled:", channelViewEnabled);
        // console.log("Current zoom:", viewport.zoom);
        // console.log("Channel zoom threshold:", channelZoomThreshold);

        if (!channelViewEnabled || viewport.zoom < channelZoomThreshold) {
          // console.log("Conditions not met, returning without fetching");
          setChannelData([]);
          return;
        }

        setIsLoadingChannels(true);
        try {
          const { longitude, latitude, width, height } = viewport;
          const [west, south] = new WebMercatorViewport(viewport).unproject([
            0,
            height,
          ]);
          const [east, north] = new WebMercatorViewport(viewport).unproject([
            width,
            0,
          ]);

          console.log("Fetching channel data with bounds:", {
            west,
            south,
            east,
            north,
          });

          const response = await authApi.get("/api/search/channels", {
            params: {
              min_lng: Math.max(-180, west),
              min_lat: Math.max(-90, south),
              max_lng: Math.min(180, east),
              max_lat: Math.min(90, north),
            },
          });
          console.log("Fetched channel data:", response.data);
          setChannelData(response.data);
        } catch (error) {
          console.error("Error fetching channel data:", error);
          showToast("Error fetching channel data", "danger", IconNames.ERROR);
        } finally {
          setIsLoadingChannels(false);
        }
      }, 500),
      [channelViewEnabled, channelZoomThreshold, showToast]
    );

    const shouldFetchChannelData = useCallback(
      (newViewState) => {
        // console.log("Checking if should fetch channel data");
        // console.log("channelViewEnabled:", channelViewEnabled);
        // console.log("New zoom:", newViewState.zoom);
        // console.log("Channel zoom threshold:", channelZoomThreshold);

        if (!channelViewEnabled || newViewState.zoom < channelZoomThreshold) {
          // console.log("Basic conditions not met, should not fetch");
          return false;
        }

        const prevViewState = previousViewStateRef.current;
        if (!prevViewState) {
          console.log("No previous view state, should fetch");
          return true;
        }

        const relevantKeys = ["longitude", "latitude", "zoom"];
        const shouldFetch = relevantKeys.some(
          (key) => Math.abs(newViewState[key] - prevViewState[key]) > 0.001
        );
        console.log("Should fetch based on view state change:", shouldFetch);
        return shouldFetch;
      },
      [channelViewEnabled, channelZoomThreshold]
    );

    const debouncedSetMousePosition = useCallback(
      debounce((position) => setMousePosition(position), 100),
      []
    );

    useEffect(() => {
      if (flyToState) {
        setViewState(flyToState);
        onFlyToComplete();
      }
    }, [flyToState, onFlyToComplete]);

    const handleViewStateChange = useCallback(
      ({ viewState: newViewState }) => {
        // console.log("View state changed:", newViewState);
        setViewState(newViewState);

        if (shouldFetchChannelData(newViewState) && !showChannels) {
          // console.log("Calling fetchChannelData from handleViewStateChange");
          fetchChannelData(newViewState);
          if (!showChannels) {
            setShowChannels(true);
          }
        } else {
          // console.log("Not fetching channel data due to insignificant change");
          if (showChannels) {
            setShowChannels(false);
          }
        }

        previousViewStateRef.current = newViewState;
      },
      [fetchChannelData, shouldFetchChannelData]
    );

    useEffect(() => {
      // console.log("useEffect for channel data fetching triggered");
      // console.log("channelViewEnabled:", channelViewEnabled);
      // console.log("viewState:", viewState);

      if (channelViewEnabled && viewState.zoom >= channelZoomThreshold) {
        // console.log("Conditions met, calling fetchChannelData");
        fetchChannelData(viewState);
      } else {
        // console.log("Conditions not met, not fetching channel data");
      }
    }, [channelViewEnabled, viewState, channelZoomThreshold, fetchChannelData]);

    const [isHovering, setIsHovering] = useState(false);

    /* Object Drawing */
    const [mode, setMode] = useState(() => "GeoJsonEditMode");
    const [editableFeatures, setEditableFeatures] = useState({
      type: "FeatureCollection",
      features: [],
    });
    const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([]);

    const handleEditableLayerClick = useCallback((info) => {
      if (info.object) {
        setSelectedFeatureIndexes([info.index]);
      } else {
        setSelectedFeatureIndexes([]);
      }
    }, []);

    const handleEditableLayerRightClick = useCallback((info, event) => {
      console.log("Editable layer right-click:", info.object);
      if (info.object) {
        setFeatureSelected(info.object);
        setMousePosition({ x: event.clientX, y: event.clientY });
      }
    }, []);

    const handleEdit = useCallback(({ updatedData, editType }) => {
      setEditableFeatures(updatedData);

      if (editType === "addFeature") {
        // Switch back to view mode
        setMode("GeoJsonEditMode");
      }
    }, []);

    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);

    const handleEditableFeatureContextMenu = async (action, feature) => {
      switch (action) {
        case "deleteFeature":
          setEditableFeatures({
            ...editableFeatures,
            features: editableFeatures.features.filter((f) => f !== feature),
          });
          break;
        case "searchPolygon":
          console.log("searchPolygon clicked");
          console.log(feature);
          await handlePolygonSearch(feature.geometry);
          break;
        case "advancedSearch":
          setSelectedFeature(feature);
          setIsAdvancedSearchOpen(true);
          break;
      }
      setMousePosition(null);
    };

    const editableLayer = useMemo(
      () =>
        createEditableLayer(
          editableFeatures,
          mode,
          selectedFeatureIndexes,
          handleEdit,
          handleEditableLayerClick,
          handleEditableLayerRightClick
        ),
      [
        editableFeatures,
        mode,
        selectedFeatureIndexes,
        handleEdit,
        handleEditableLayerClick,
        handleEditableLayerRightClick,
      ]
    );

    const [showHeatmap, setShowHeatmap] = useState(false);
    const [isHeatmapLoading, setIsHeatmapLoading] = useState(false);
    const toggleDrawingMode = (newMode) => {
      setMode((currentMode) => (currentMode === newMode ? null : newMode));
    };

    // context menu
    const [contextMenu, setContextMenu] = useState(true);

    // for layer hover
    const [hoverInfo, setHoverInfo] = useState(null);
    const [hoveredObject, setHoveredObject] = useState(null);

    const getTopObject = useCallback((info) => {
      if (!info.objects || info.objects.length === 0) return null;
      // Sort objects by their depth (assuming a 'depth' property exists)
      // If not, you might need to implement a custom sorting logic
      const sortedObjects = info.objects.sort(
        (a, b) => (b.depth || 0) - (a.depth || 0)
      );
      return sortedObjects[0];
    }, []);

    const onHover = useCallback(
      ({ x, y, object, objects }) => {
        const topObject = getTopObject({ objects });
        setHoveredObject(topObject);
        // Set hover info for tooltip if you're using one
        setHoverInfo(topObject ? { x, y, object: topObject } : null);
      },
      [getTopObject]
    );

    const getTooltipContent = useCallback((object) => {
      if (!object) return null;

      if (object.properties) {
        // For telegram layers and other GeoJSON-like objects
        if (object.properties.user_id) {
          return `User ID: ${object.properties.user_id}`;
        }
        // For editable layer objects
        if (object.properties.id) {
          return `Feature ID: ${object.properties.id}`;
        }
      }

      // For channel layer objects
      if (object.id) {
        return `Channel ID: ${object.id}`;
      }

      // Fallback for any other type of object
      return "Hover object";
    }, []);

    const [featureSelected, setFeatureSelected] = useState(null);

    const [mousePosition, setMousePosition] = useState({});

    // Add a new state variable to track the pulsing dot coordinates
    const [pulsingDotCoordinates, setPulsingDotCoordinates] = useState(null);

    // config brought from server-side
    const [baseLayers, setBaseLayers] = useState([]);
    const [selectedBaseLayer, setSelectedBaseLayer] = useState([null]);
    const [mapboxToken, setMapboxToken] = useState(null);

    // loading state
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);
    const [triangulatedDataArray, setTriangulatedDataArray] = useState(null);
    const [heatmapData, setHeatmapData] = useState([]); // [ [lat, lng, count], ...

    // for 'object view'
    const [selectedMapUserInfo, setSelectedMapUserInfo] = useState(null);

    // add in more intersections to triangulatedDataArray
    // make sure to remove the ones this is replacing, so that we can change between bucket sizes
    const handleIntersectPolygons = (polygons) => {
      console.log("Handling intersections");
      if (polygons.length === 0) return;

      const newUserId = polygons[0].properties.user_id;

      setTriangulatedDataArray((prevDataArray) => {
        console.log("Previous data array:", prevDataArray);
        if (!prevDataArray) {
          return polygons;
        }
        console.log("New polygons:", polygons);
        console.log("New user ID:", newUserId);

        // Filter out any polygons with the same user_id
        const filteredDataArray = prevDataArray.filter(
          (polygon) => polygon.properties.user_id !== newUserId
        );

        console.log("Filtered data array before adding:", filteredDataArray);

        // Append the new polygons
        console.log("Appending and returning...");
        return [...filteredDataArray, ...polygons];
      });
    };

    useEffect(() => {
      console.log("MapComponent rendered");
    }, []);

    // function getCursor({ isHovering }) {
    //   console.log("getCursor called with isHovering:", isHovering);
    //   const cursor = isHovering ? "pointer" : "grab";
    //   console.log("Returning cursor:", cursor);
    //   return cursor;
    // }

    // const handleHover = useCallback(({ object }) => {
    //   console.log("Handling hover");
    //   setIsHovering((prevIsHovering) => {
    //     const newIsHovering = Boolean(object);
    //     if (prevIsHovering !== newIsHovering) {
    //       return newIsHovering;
    //     }
    //     return prevIsHovering;
    //   });
    // }, []);

    // Run when component mounts
    useEffect(() => {
      const fetchConfig = async () => {
        try {
          const response = await authApi.get("/config/map");
          const configData = response.data;

          // Only update state if configData differs
          setMapboxToken((prev) =>
            prev !== configData.mapbox_token ? configData.mapbox_token : prev
          );

          setBaseLayers((prev) =>
            JSON.stringify(prev) !== JSON.stringify(configData.base_layers)
              ? configData.base_layers
              : prev
          );

          setSelectedBaseLayer((prev) =>
            prev !== configData.base_layers[0].value
              ? configData.base_layers[0].value
              : prev
          );

          setViewState((prev) =>
            JSON.stringify(prev) !==
            JSON.stringify(configData.initial_view_state)
              ? configData.initial_view_state
              : prev
          );

          setIsLoadingConfig(false);
        } catch (error) {
          showToast("Error fetching config", "danger", IconNames.ERROR);
        }
      };

      fetchConfig();
    }, []);

    useEffect(() => {
      const fetchHeatmapData = async () => {
        if (showHeatmap) {
          setIsHeatmapLoading(true);
          try {
            const response = await authApi.get("/api/search/get_heatmap");
            setHeatmapData(response.data);
          } catch (error) {
            console.error("Error fetching heatmap data:", error);
            showToast("Error fetching heatmap data", "danger", IconNames.ERROR);
          } finally {
            setIsHeatmapLoading(false);
          }
        } else {
          setHeatmapData([]);
        }
      };

      fetchHeatmapData();
    }, [showHeatmap]);

    const handleContextMenuClose = () => {
      setContextMenu(null);
    };

    const handleDbSearchPoint = async (point) => {
      setShownUsers(selectedUserIds);
      if (!isObjectTableVisible) {
        setObjectTableIsVisible(true);
      }

      if (!point) {
        console.log("No point provided!");
        setIsLoadingObjectTable(false);
        setPulsingDotCoordinates(null);
        return;
      }

      setPulsingDotCoordinates(point);
      setIsLoadingObjectTable(true);

      let lat = point[1];
      let lon = point[0];

      try {
        const response = await authApi.post("/api/search/query_radius", {
          point: [lon, lat],
          radius_meters: 20000, // Adjust the radius as needed
        });

        const data = response.data;
        if (data.length === 0) {
          console.log("No results found!");
          return;
        }

        setShownUsers((prevShownUsers) => {
          const newShownUsers = [...prevShownUsers];
          for (let i = 0; i < data.length; i++) {
            const user = data[i];
            if (!newShownUsers.includes(user.telegram_user_id)) {
              newShownUsers.push(user.telegram_user_id);
            }
          }
          return newShownUsers;
        });
      } catch (error) {
        console.error("Error:", error);
        showToast(
          "Error fetching database search results",
          "danger",
          IconNames.ERROR
        );
      } finally {
        setPulsingDotCoordinates(null);
      }
    };

    const handlePolygonSearch = async (geometry) => {
      setShownUsers(selectedUserIds);
      if (!isObjectTableVisible) {
        setObjectTableIsVisible(true);
      }

      setIsLoadingObjectTable(true);

      try {
        const response = await authApi.post("/api/search/search_polygon", {
          geometry: geometry,
        });

        const data = response.data;
        if (data.length === 0) {
          console.log("No results found within the polygon!");
          showToast(
            "No results found within the polygon",
            "warning",
            IconNames.INFO_SIGN
          );
          return;
        }

        setShownUsers((prevShownUsers) => {
          const newShownUsers = [...prevShownUsers];
          for (let i = 0; i < data.length; i++) {
            const user = data[i];
            if (!newShownUsers.includes(user.telegram_user_id)) {
              newShownUsers.push(user.telegram_user_id);
            }
          }
          return newShownUsers;
        });

        if (data.length === 10000) {
          showToast(
            "Results are limited. There may be more data available.",
            "warning",
            IconNames.WARNING_SIGN
          );
        } else {
          showToast(
            `Found ${data.length} results within the polygon`,
            "success",
            IconNames.TICK
          );
        }
      } catch (error) {
        console.error("Error:", error);
        showToast("Error searching within polygon", "danger", IconNames.ERROR);
      } finally {
        setIsLoadingObjectTable(false);
      }
    };

    const handleAdvancedSearch = async (geometry, tagIds) => {
      setIsLoadingObjectTable(true);
      try {
        const response = await authApi.post(
          "/api/search/search_polygon_with_tags",
          {
            geometry: geometry,
            tag_ids: tagIds,
          }
        );

        const taskId = response.data.task_id;

        showToast("Advanced search started...", "success", IconNames.COG);

        // Start polling for results
        const pollInterval = setInterval(async () => {
          const statusResponse = await authApi.get(
            `/api/search/search_status/${taskId}`
          );
          if (statusResponse.data.state === "SUCCESS") {
            clearInterval(pollInterval);
            setIsLoadingObjectTable(false);

            const data = statusResponse.data.result;
            if (data.length === 0) {
              showToast("No results found", "warning", IconNames.INFO_SIGN);
            } else if (data.length === 10000) {
              showToast(
                "Results are limited to 10000 users. There may be more data available.",
                "warning",
                IconNames.WARNING_SIGN
              );
            } else {
              showToast(
                `Found ${data.length} results`,
                "success",
                IconNames.TICK
              );
            }

            setShownUsers((prevShownUsers) => {
              const newShownUsers = [...prevShownUsers];
              for (let i = 0; i < data.length; i++) {
                const user = data[i];
                if (!newShownUsers.includes(user.telegram_user_id)) {
                  newShownUsers.push(user.telegram_user_id);
                }
              }
              return newShownUsers;
            });
          } else if (statusResponse.data.state === "FAILURE") {
            clearInterval(pollInterval);
            setIsLoadingObjectTable(false);
            showToast(
              "Error performing advanced search",
              "danger",
              IconNames.ERROR
            );
          }
        }, 1000); // Poll every second
      } catch (error) {
        console.error("Error:", error);
        setIsLoadingObjectTable(false);
        showToast(
          "Error performing advanced search",
          "danger",
          IconNames.ERROR
        );
      }
    };

    const handleContextMenu = useCallback(
      (event) => {
        // Get the map container element
        const mapContainer = deckRef.current.deck.canvas;

        // Get the bounding rectangle of the map container
        const rect = mapContainer.getBoundingClientRect();

        // Calculate coordinates relative to the map container
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const viewport = new WebMercatorViewport(viewState);
        const [longitude, latitude] = viewport.unproject([x, y]);

        console.log("Context menu at:", latitude, longitude);
        setMousePosition([longitude, latitude]);
      },
      [viewState, deckRef]
    );

    const handleContextMenuItemClick = async (action) => {
      if (action === "dbSearchPoint") {
        await handleDbSearchPoint(mousePosition);
      }
      handleContextMenuClose();
    };

    useEffect(() => {
      // Trigger a map resize when the object table visibility changes
      window.dispatchEvent(new Event("resize"));
    }, [isObjectTableVisible]);

    useEffect(() => {
      const fetchUserInfo = async () => {
        if (selectedMapUser) {
          // Checking selected map user
          console.log("Selected map user: ", selectedMapUser);

          try {
            const response = await authApi.get(
              `/api/telegram/user_info?user_id=${selectedMapUser}`
            );
            const userInfo = response.data;
            setSelectedMapUserInfo(userInfo);
          } catch (error) {
            console.error("Error fetching user info:", error);
          }
        } else {
          setSelectedMapUserInfo(null);
        }
      };

      fetchUserInfo();
    }, [selectedMapUser, shownUsers]);

    const deckGLClickHandler = useCallback(
      (info, event) => {
        console.log("DeckGL click handler called");

        if (info.layer) {
          console.log("Layer ID:", info.layer.id);
        } else {
          console.log("No layer selected");
          setSelectedMapUser(null);
        }
        if (mousePosition === null) {
          console.log("Mouse position is null");
        }
        if (!event.rightButton || !info.object) {
          setFeatureSelected(null);
        }
        if (info.layer && !info.layer.id.startsWith("telegram")) {
          console.log("Setting map user to null");
          setSelectedMapUser(null);
        }
      },
      [debouncedSetMousePosition]
    );

    const heatmapLayer = useMemo(() => {
      return createHeatmapLayer(heatmapData);
    }, [heatmapData]);

    const hoverLayer = useMemo(() => {
      return createHoverLayer(hoveredObject);
    }, [hoveredObject]);

    const pulsingDotLayers = useMemo(() => {
      return createPulsingEffectLayers(pulsingDotCoordinates);
    }, [pulsingDotCoordinates]);

    const channelLayer = useMemo(() => {
      console.log("Recalculating channel layer");
      console.log("channelViewEnabled:", channelViewEnabled);
      console.log("showChannels:", showChannels);
      console.log("channelData length:", channelData.length);
      console.log("viewState.zoom:", viewState.zoom);

      if (channelViewEnabled && showChannels && channelData.length > 0) {
        console.log("Creating channel layer");
        return createChannelLayer(channelData, viewState.zoom);
      }
      console.log("Not creating channel layer");
      return null;
    }, [channelViewEnabled, showChannels, channelData, viewState.zoom]);

    const telegramLayers = useMemo(() => {
      const layers = createTelegramLayers(
        trackData,
        circleData,
        triangulatedDataArray,
        selectedMapUser,
        setSelectedMapUser,
        isHovering,
        triangulatedUsers,
        setTriangulatedUsers,
        timestampSliderEnabled,
        selectedUserIds,
        timestampSelectedStart,
        timestampSelectedEnd,
        includeNullTimestampInFilter
      );

      if (channelLayer) {
        layers.push(channelLayer);
      }

      return layers;
    }, [
      trackData,
      circleData,
      triangulatedDataArray,
      selectedMapUser,
      setSelectedMapUser,
      isHovering,
      triangulatedUsers,
      setTriangulatedUsers,
      timestampSliderEnabled,
      timestampSelectedStart,
      timestampSelectedEnd,
      includeNullTimestampInFilter,
      channelViewEnabled,
      showChannels,
      channelData,
      viewState.zoom,
    ]);

    useEffect(() => {
      const newLayers = [
        editableLayer,
        heatmapLayer,
        ...telegramLayers,
        ...pulsingDotLayers,
        hoverLayer,
      ];
      if (!layersEqual(layers, newLayers)) {
        setLayers(newLayers);
      }
    }, [
      layers,
      editableLayer,
      heatmapLayer,
      telegramLayers,
      pulsingDotLayers,
      hoverLayer,
      setLayers,
    ]);

    const renderMainContextMenu = (props) => (
      <Menu>
        <ContextMenuItems
          onItemClick={handleContextMenuItemClick}
          featureSelected={featureSelected}
          setIsSearchOverlayOpen={setIsSearchOverlayOpen}
        />
      </Menu>
    );

    return (
      <div
        style={{ height: "100%", width: "100%", position: "relative" }}
        onContextMenu={(evt) => evt.preventDefault()}
      >
        <ContextMenu
          content={
            featureSelected ? (
              <EditableFeatureContextMenu
                onItemClick={handleEditableFeatureContextMenu}
                feature={featureSelected}
              />
            ) : (
              renderMainContextMenu({ mousePosition })
            )
          }
          onContextMenu={handleContextMenu}
          // onClick={deckGLClickHandler}
          position={mousePosition}
          onClose={() => debouncedSetMousePosition(null)}
          // onClose={() => setMousePosition(null)}
          disabled={false}
        >
          {!isLoadingConfig && (
            <DeckGL
              viewState={viewState}
              onViewStateChange={handleViewStateChange}
              layers={layers}
              onClick={deckGLClickHandler}
              onHover={onHover}
              pickingRadius={5}
              getTooltip={({ object }) => getTooltipContent(object)}
              getCursor={({ isHovering }) => (isHovering ? "pointer" : "grab")}
              controller={true}
              ref={deckRef}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <Map
                mapboxAccessToken={mapboxToken}
                mapStyle={selectedBaseLayer}
                style={{ width: "100%", height: "100%" }}
              />
              {hoverInfo && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    pointerEvents: "none",
                    left: hoverInfo.x,
                    top: hoverInfo.y,
                  }}
                >
                  <Tooltip open={true} title={hoverInfo.object.hoverInfo}>
                    <div />
                  </Tooltip>
                </div>
              )}
            </DeckGL>
          )}
          <MapControls
            mode={mode}
            setMode={setMode}
            showHeatmap={showHeatmap}
            setShowHeatmap={setShowHeatmap}
            isHeatmapLoading={isHeatmapLoading}
            baseLayers={baseLayers}
            selectedBaseLayer={selectedBaseLayer}
            setSelectedBaseLayer={setSelectedBaseLayer}
            setIsSearchOverlayOpen={setIsSearchOverlayOpen}
            channelViewEnabled={channelViewEnabled}
            setChannelViewEnabled={setChannelViewEnabled}
          />
          <UserInfoPopup
            user={selectedMapUserInfo}
            userTags={userTags[selectedMapUserInfo?.user_id] ?? []}
            onIntersectPolygons={handleIntersectPolygons}
            triangulatedUsers={triangulatedUsers}
            setTriangulatedUsers={setTriangulatedUsers}
            zoomToUser={zoomToUser}
            bucketRange={bucketRange}
            setBucketRange={setBucketRange}
          />
          <AdvancedSearchModal
            isOpen={isAdvancedSearchOpen}
            onClose={() => setIsAdvancedSearchOpen(false)}
            onSubmit={handleAdvancedSearch}
            geometry={selectedFeature ? selectedFeature.geometry : null}
          />
          {/* Loading spinner */}
          {isLoadingChannels && (
            <div
              style={{
                position: "absolute",
                bottom: 20,
                right: 20,
                zIndex: 1000,
              }}
            >
              <CircularProgress
                size={30}
                thickness={3}
                sx={{
                  color: "rgba(0, 0, 0, 0.7)", // Light grey color
                }}
              />
            </div>
          )}
        </ContextMenu>
      </div>
    );
  }
);

export default React.memo(MapComponent);
