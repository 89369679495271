// geoUtils.js
import { point, circle } from "@turf/turf";

export const createCirclePolygon = (longitude, latitude, radiusInMeters) => {
  const centerPoint = point([longitude, latitude]);
  const circleOptions = {
    steps: 64,
    units: "meters",
  };
  const circlePolygon = circle(centerPoint, radiusInMeters, circleOptions);
  return circlePolygon.geometry;
};
