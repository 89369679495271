import React from "react";
import { OverlayToaster, Position } from "@blueprintjs/core";

const ToasterContext = React.createContext();

function ToasterProvider({ children }) {
  const toaster = React.useRef(null);

  return (
    <ToasterContext.Provider value={toaster}>
      {children}
      <OverlayToaster ref={toaster} position={Position.TOP} />
    </ToasterContext.Provider>
  );
}

export { ToasterContext, ToasterProvider };
