import React from "react";
import ReactDOM from "react-dom/client"; // Make sure to use /client for ReactDOM in React 18+
import { CssBaseline } from "@mui/material"; // Import CssBaseline for consistent baseline styling
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

// Pages
import MappingPage from "./pages/MappingPage";
import LoginPage from "./pages/LoginPage";
import GrafanaPage from "./pages/GrafanaPage";
import ExperimentalPage from "./pages/ExperimentalPage";
import HomePage from "./pages/HomePage";
import UserPage from "./pages/UserPage";
import NotFoundPage from "./pages/NotFoundPage";
import ChannelPage from "./pages/ChannelPage";
import AdminLocationRequestsPage from "./pages/AdminLocationRequestsPage";
import CreateLocationRequestPage from "./pages/CreateLocationRequestPage";
import TelegramSessionsPage from "./pages/TelegramSessionsPage";
import AdminChannelCrawlPage from "./pages/AdminChannelCrawlPage";

// Components
import AppNavbar from "./components/AppNavBar";
import ErrorBoundary from "./components/ErrorBoundary";

// Restricted Route
import Protected from "./components/Protected";

// Contexts
import { AuthProvider } from "./contexts/AuthContext";
import { ToasterProvider } from "./contexts/ToasterContext";

// Import CSS file
import "./main.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function AppWrapper() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  if (isLoginPage) {
    console.log("Login Page");
  }

  return (
    <div className="bp5-dark">
      {!isLoginPage && <AppNavbar />}
      <div style={{ paddingTop: "50px" }}>
        <Routes>
          <Route path="/" element={<Navigate to="/globe" replace />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/app"
            element={
              <Protected>
                <MappingPage />
              </Protected>
            }
          />
          <Route
            path="/globe"
            element={
              <Protected>
                <HomePage />
              </Protected>
            }
          />
          <Route
            path="/admin/create-location-request"
            element={
              <Protected>
                <CreateLocationRequestPage />
              </Protected>
            }
          />
          <Route
            path="/app"
            element={
              <Protected>
                <MappingPage />
              </Protected>
            }
          />
          <Route
            path="/testing"
            element={
              <Protected>
                <ExperimentalPage />
              </Protected>
            }
          />
          <Route
            path="/user/:userId"
            element={
              <Protected>
                <UserPage />
              </Protected>
            }
          />
          <Route
            path="/channel/:channelId"
            element={
              <Protected>
                <ChannelPage />
              </Protected>
            }
          />
          <Route
            path="/grafana/d/adjctpw2v5udcd/collection-metric-dashboard"
            element={
              <Protected>
                <GrafanaPage />
              </Protected>
            }
          />
          <Route
            path="/grafana/d/bdmzo3kkzjk74a/cron-job-monitoring"
            element={
              <Protected>
                <GrafanaPage />
              </Protected>
            }
          />
          <Route
            path="/admin/location-requests"
            element={
              <Protected>
                <AdminLocationRequestsPage />
              </Protected>
            }
          />
          <Route
            path="/admin/telegram-sessions"
            element={
              <Protected>
                <TelegramSessionsPage />
              </Protected>
            }
          />
          <Route
            path="/admin/channel-crawl"
            element={
              <Protected>
                <AdminChannelCrawlPage />
              </Protected>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <ToasterProvider>
        <AuthProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Router>
              <AppWrapper />
            </Router>
          </ThemeProvider>
        </AuthProvider>
      </ToasterProvider>
    </ErrorBoundary>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
