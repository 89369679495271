import {
  ScatterplotLayer,
  GeoJsonLayer,
  IconLayer,
  TextLayer,
  CompositeLayer,
} from "@deck.gl/layers";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import {
  GeoJsonEditMode,
  EditableGeoJsonLayer,
  DrawCircleFromCenterMode,
  DrawPolygonMode,
  MeasureDistanceMode,
} from "nebula.gl";
import PulseEffect from "../components/PulseEffect"; // Assuming you have this defined somewhere
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export const layersEqual = (prevLayers, newLayers) => {
  if (prevLayers === newLayers) return true;
  if (prevLayers == null || newLayers == null) return false;
  if (prevLayers.length !== newLayers.length) return false;

  for (let i = 0; i < prevLayers.length; ++i) {
    if (prevLayers[i] !== newLayers[i]) return false;
  }
  return true;
};

export const createEditableLayer = (
  features,
  mode,
  selectedFeatureIndexes,
  onEdit,
  onClick,
  onRightClick
) => {
  if (features.length === 0) {
    return null;
  }

  const modeMapping = {
    GeoJsonEditMode: GeoJsonEditMode,
    DrawPolygonMode: DrawPolygonMode,
    DrawCircleFromCenterMode: DrawCircleFromCenterMode,
    MeasureDistanceMode: MeasureDistanceMode,
  };

  return new EditableGeoJsonLayer({
    id: "geojson-layer-editable",
    data: features,
    mode: modeMapping[mode],
    pickable: true,
    selectedFeatureIndexes: selectedFeatureIndexes,
    onEdit: onEdit,
    autoHighlight: true,
    onClick: (info, event) => {
      if (event.rightButton) {
        onRightClick(info, event);
      } else {
        onClick(info, event);
      }
    },
    getLineColor: (f) =>
      selectedFeatureIndexes.includes(f.properties.id)
        ? [255, 0, 0, 255]
        : [0, 0, 255, 255],
    getFillColor: (f) =>
      selectedFeatureIndexes.includes(f.properties.id)
        ? [255, 0, 0, 50]
        : [0, 0, 255, 50],
    getLineWidth: (f) =>
      selectedFeatureIndexes.includes(f.properties.id) ? 3 : 1,
    // onContextMenu: onContextMenuEditableLayer,
    // onHover: onHoverEditableLayer,
    // updateTriggers: {
    // getLineColor: [selectedFeatureIndexes],
    // getLineWidth: [selectedFeatureIndexes],
    // },
  });
};

const createChatIconCanvas = () => {
  const canvas = document.createElement("canvas");
  canvas.width = 64;
  canvas.height = 64;
  const ctx = canvas.getContext("2d");

  // Draw a simple chat bubble icon
  // nice 'success' green
  ctx.fillStyle = "#00FF00";
  ctx.beginPath();
  ctx.moveTo(5, 5);
  ctx.lineTo(59, 5);
  ctx.lineTo(59, 45);
  ctx.lineTo(35, 45);
  ctx.lineTo(25, 59);
  ctx.lineTo(15, 45);
  ctx.lineTo(5, 45);
  ctx.closePath();
  ctx.fill();

  return canvas;
};

const chatIconCanvas = createChatIconCanvas();
const chatIconUrl = chatIconCanvas.toDataURL();

export const createChannelLayer = (channelData, zoom) => {
  console.log("Creating a channel layer");

  return new IconLayer({
    id: "channel-layer",
    data: channelData,
    getPosition: (d) => [d.avg_longitude, d.avg_latitude],
    getIcon: (d) => ({
      url: d.photo_id
        ? `/static-files/channel_photos/${d.id}_${d.photo_id}.jpg`
        : chatIconUrl,
      width: 64,
      height: 64,
      anchorY: 64,
    }),
    getSize: (d) => Math.max(24, Math.min(64, 2 ** (zoom - 10))), // Exponential scaling
    sizeScale: 1,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        info.object.hoveredChannel = info.object;
      }
    },
    onClick: (info) => {
      if (info.object) {
        console.log("Clicked channel:", info.object);
      }
    },
    updateTriggers: {
      getSize: [zoom],
    },
  });
};

export const createSearchResultsRadiusLayer = (searchResults) => {
  return new ScatterplotLayer({
    id: "search-results",
    data: searchResults,
    getPosition: (d) => [d.longitude, d.latitude],
    getRadius: (d) => d.radius_metres,
    opacity: 0.1,
    pickable: true,
    stroked: true,
    filled: false,
    radiusScale: 1,
    radiusMinPixels: 1,
    radiusMaxPixels: 1000,
    lineWidthMinPixels: 1,
    getLineColor: [0, 0, 0, 255],
    getLineWidth: 100,
  });
};

export const createSearchResultCentrePointsLayer = (searchResults) => {
  return new ScatterplotLayer({
    id: "centre-points",
    data: searchResults,
    getPosition: (d) => [d.longitude, d.latitude],
    getRadius: 1,
    pickable: true,
    stroked: false,
    filled: true,
    radiusScale: 100,
    radiusMinPixels: 1,
    getFillColor: [255, 0, 255, 255],
  });
};

export const createPulsingDotLayer = (pulsingDotCoordinates) => {
  return new ScatterplotLayer({
    id: "pulsing-dot-layer",
    data: pulsingDotCoordinates ? [{ coordinates: pulsingDotCoordinates }] : [],
    getPosition: (d) => d.coordinates,
    getRadius: 10,
    getFillColor: [0, 0, 0, 0],
    getLineColor: [0, 0, 0],
    lineWidthMinPixels: 2,
    stroked: true,
    radiusScale: 8,
    radiusMinPixels: 1,
    radiusMaxPixels: 500,
    pickable: false,
    autoHighlight: false,
  });
};

export const createPulsingEffectLayers = (pulsingDotCoordinates) => {
  return pulsingDotCoordinates
    ? PulseEffect({ coordinates: pulsingDotCoordinates })
    : [];
};

export const createLocationRequestGridPointsLayer = (gridPoints) => {
  return new GeoJsonLayer({
    id: "location-request-grid-points",
    data: gridPoints,
    filled: true,
    pointRadiusMinPixels: 5,
    pointRadiusScale: 3,
    getPointRadius: 5,
    getFillColor: [255, 0, 0, 70],
    getLineColor: [0, 0, 0, 255],
    lineWidthMinPixels: 1,
    zIndex: 1,
  });
};

export const createGridSearchGridPointsLayer = (gridPoints) => {
  return new GeoJsonLayer({
    id: "grid-search-grid-points",
    data: gridPoints,
    filled: true,
    pointRadiusMinPixels: 5,
    pointRadiusScale: 3,
    getPointRadius: 5,
    getFillColor: [0, 0, 255, 70],
    getLineColor: [0, 0, 0, 255],
    lineWidthMinPixels: 1,
    zIndex: 1,
  });
};

export const createHeatmapLayer = (heatmapData) => {
  return new HeatmapLayer({
    id: "heatmap-layer",
    data: heatmapData,
    getPosition: (d) => [d.longitude, d.latitude],
    getWeight: (d) => d.count,
    radiusPixels: 100,
    intensity: 3,
    threshold: 0.01,
    colorRange: [
      [0, 255, 0, 50], // Green color with increased base opacity
      [0, 255, 0, 100], // Green color with increased opacity
      [255, 255, 0, 150], // Yellow color with increased opacity
      [255, 165, 0, 200], // Orange color with increased opacity
      [255, 0, 0, 250], // Red color with increased opacity
    ],
  });
};

export const createHoverLayer = (hoveredObject) => {
  return new GeoJsonLayer({
    id: "hover-layer",
    data: hoveredObject ? [hoveredObject] : [],
    pickable: true,
    stroked: true,
    filled: false,
    wireframe: true,
    lineWidthMinPixels: 2,
    getLineColor: [255, 255, 255],
    getLineWidth: 2,
    transitions: {
      getLineColor: 100,
      getLineWidth: 100,
    },
  });
};
