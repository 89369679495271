import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Typography, Avatar, Tooltip } from "@mui/material";
import { getUserColour } from "../utils/userColours";
import { IconNames } from "@blueprintjs/icons";
import { Button, Intent, Slider, Collapse } from "@blueprintjs/core";

import NewUserInfoCard from "./NewUserInfoCard";
import authApi from "../api/authApi.js";

const UserInfoPopup = ({
  user,
  userTags,
  onIntersectPolygons,
  triangulatedUsers,
  setTriangulatedUsers,
  zoomToUser,
  bucketRange,
  setBucketRange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isLoadingIntersect, setIsLoadingIntersect] = useState(false);
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const sliderValueRef = useRef(bucketRange);
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const toggleSlider = () => {
    setIsSliderOpen(!isSliderOpen);
  };

  const formatHours = (hours) => {
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(hours / 168);

    if (hours === 1) return "1 hour";
    if (hours < 24) return `${hours} hours`;
    if (days === 1) return "1 day";
    if (days < 7) return `${days} days`;
    if (weeks === 1) return "1 week";
    return `${weeks} weeks`;
  };

  const fetchTriangulatedPolygons = useCallback(
    async (range, userId) => {
      if (!userId) return;
      setIsLoadingIntersect(true);
      try {
        const response = await authApi.post(
          "/api/search/get_triangulated_polygons",
          {
            user_id: userId,
            bucket_duration_hours: range,
          }
        );
        const triangulatedPolygons = response.data;
        onIntersectPolygons(triangulatedPolygons);
      } catch (error) {
        console.error(`Error fetching triangulated polygons: ${error}`);
      }
      setIsLoadingIntersect(false);
    },
    [onIntersectPolygons]
  );

  const fetchProbabilityPolygons = useCallback(
    async (range, userId) => {
      if (!userId) return;
      setIsLoadingIntersect(true);
      try {
        const response = await authApi.post("/api/kde/get_user_kde", {
          user_id: userId,
          bucket_duration_hours: range,
          threshold_pct: 99,
        });
        const triangulatedPolygons = response.data;
        onIntersectPolygons(triangulatedPolygons);
      } catch (error) {
        console.error(`Error fetching triangulated polygons: ${error}`);
      }
      setIsLoadingIntersect(false);
    },
    [onIntersectPolygons]
  );

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchTriangulatedPolygons = useCallback(
    debounce((range) => {
      if (user && user.user_id) {
        fetchTriangulatedPolygons(range, user.user_id);
      }
    }, 200),
    [fetchTriangulatedPolygons, user]
  );

  const handleSliderRelease = () => {
    debouncedFetchTriangulatedPolygons(bucketRange);
  };

  const handleSliderChange = (value) => {
    setBucketRange(value);
    sliderValueRef.current = value;
  };

  const handleIntersectClick = async () => {
    if (triangulatedUsers.includes(user.user_id)) {
      const newTriangulatedUsers = triangulatedUsers.filter(
        (id) => id !== user.user_id
      );
      setTriangulatedUsers(newTriangulatedUsers);
    } else {
      await fetchTriangulatedPolygons(bucketRange, user.user_id);
      setTriangulatedUsers([...triangulatedUsers, user.user_id]);
    }
  };

  const handleMagicClick = async () => {
    if (triangulatedUsers.includes(user.user_id)) {
      const newTriangulatedUsers = triangulatedUsers.filter(
        (id) => id !== user.user_id
      );
      setTriangulatedUsers(newTriangulatedUsers);
    } else {
      await fetchProbabilityPolygons(bucketRange, user.user_id);
      setTriangulatedUsers([...triangulatedUsers, user.user_id]);
    }
  };

  if (!user) {
    return null;
  }

  const userColor = getUserColour(user.user_id);
  const borderColor = `rgb(${userColor[0]}, ${userColor[1]}, ${userColor[2]})`;

  const handleExpandClick = () => {
    setExpanded(true);
  };

  const handleCloseExpanded = () => {
    setExpanded(false);
  };

  return (
    <>
      <Box sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            position: "absolute",
            top: 16,
            right: 90,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: 2,
            borderRadius: 1,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: `2px solid ${borderColor}`,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={user.photo_url}
              alt={user.full_name}
              sx={{ width: 40, height: 40, marginRight: 2, cursor: "pointer" }}
              onClick={handleExpandClick}
            />
            <Box sx={{ color: "white", flexGrow: 1 }}>
              <Typography variant="h6" component="h3">
                {user.full_name}
              </Typography>
              <Typography variant="body1" component="p">
                User ID: {user.user_id}
              </Typography>
            </Box>
            <Tooltip title="Intersect Detections">
              <Button
                icon={
                  triangulatedUsers.includes(user.user_id)
                    ? IconNames.UNRESOLVE
                    : IconNames.INNER_JOIN
                }
                large
                loading={isLoadingIntersect}
                minimal
                intent={
                  triangulatedUsers.includes(user.user_id)
                    ? Intent.SUCCESS
                    : Intent.WARNING
                }
                onClick={handleIntersectClick}
                sx={{ "&:focus": { outline: "none", boxShadow: "none" } }}
              />
            </Tooltip>
            <Tooltip title="Triangulation V2">
              <Button
                icon={IconNames.CLEAN}
                large
                loading={isLoadingIntersect}
                minimal
                intent={
                  triangulatedUsers.includes(user.user_id)
                    ? Intent.SUCCESS
                    : Intent.WARNING
                }
                onClick={handleMagicClick}
                sx={{ "&:focus": { outline: "none", boxShadow: "none" } }}
              />
            </Tooltip>
            <Tooltip title="Locate User">
              <Button
                icon={IconNames.LOCATE}
                large
                minimal
                intent={Intent.PRIMARY}
                onClick={() => zoomToUser(user.user_id)}
                sx={{ "&:focus": { outline: "none", boxShadow: "none" } }}
              />
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginTop: 2,
              px: 2,
              justifyContent: "center",
            }}
          >
            <Button
              onClick={toggleSlider}
              fill={true}
              minimal={true}
              sx={{ color: "white" }}
              disabled={!triangulatedUsers.includes(user.user_id)}
              rightIcon={IconNames.CHEVRON_DOWN}
            >
              Time Bucket: {formatHours(bucketRange)}
            </Button>
            <Collapse isOpen={isSliderOpen}>
              <Box sx={{ width: "100%", mt: 2 }}>
                <Slider
                  min={0}
                  max={168}
                  stepSize={1}
                  labelStepSize={24}
                  onChange={handleSliderChange}
                  // onRelease={handleSliderRelease}
                  value={sliderValueRef.current}
                  labelRenderer={(value) => formatHours(value)}
                  intent="primary"
                  showTrackFill
                  trackStyle={{ backgroundColor: "lightgrey" }}
                  disabled={!triangulatedUsers.includes(user.user_id)}
                />
              </Box>
            </Collapse>
          </Box>
        </Box>
        <NewUserInfoCard
          open={expanded}
          onClose={handleCloseExpanded}
          user={user}
          borderColor={borderColor}
          userTags={userTags}
        />
      </Box>
    </>
  );
};

export default UserInfoPopup;
