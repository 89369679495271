import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Tabs,
  Tab,
  Grid,
  Tooltip,
  Paper,
  Avatar,
  Divider,
  Card,
  CardContent,
  CardMedia,
  Badge,
  Button,
  Modal,
} from "@mui/material";
import DeckGL from "@deck.gl/react";
import { WebMercatorViewport } from "@deck.gl/core";
import { Map } from "react-map-gl";
import { ScatterplotLayer } from "@deck.gl/layers";
import authApi from "../api/authApi";
import {
  Person,
  Phone,
  AccessTime,
  Android,
  Warning,
  ReportProblem,
} from "@mui/icons-material";
import { formatDistanceToNow, parseISO, format } from "date-fns";
import { Chip } from "@mui/material";
import { LocalOffer } from "@mui/icons-material";

const UserPage = () => {
  const { userId } = useParams();
  const [mapboxToken, setMapboxToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userTags, setUserTags] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [locationHistory, setLocationHistory] = useState([]);
  const [userChannels, setUserChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 1,
    bearing: 0,
    pitch: 0,
  });
  const [zoomedPhoto, setZoomedPhoto] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const [
        userInfoResponse,
        photosResponse,
        locationResponse,
        channelsResponse,
      ] = await Promise.all([
        authApi.get(`/api/telegram/user_info?user_id=${userId}`),
        authApi.get(`/api/telegram/user_photos/${userId}`),
        authApi.get(`/api/search/get_track?user_id=${userId}`),
        authApi.get(`/api/channel/user_channels/${userId}`),
      ]);

      setUserInfo(userInfoResponse.data);
      setUserTags(userInfoResponse.data.tags || []);
      setPhotos(photosResponse.data.photos);
      setLocationHistory(locationResponse.data.track_points);
      setUserChannels(channelsResponse.data);

      if (locationResponse.data.track_points.length > 0) {
        // const { longitude, latitude } = locationResponse.data.track_points[0];
        const points = locationResponse.data.track_points.map((point) => [
          point.longitude,
          point.latitude,
        ]);

        console.log("points:", points);

        const bounds = [
          [
            Math.min(...points.map((p) => p[0])),
            Math.min(...points.map((p) => p[1])),
          ],
          [
            Math.max(...points.map((p) => p[0])),
            Math.max(...points.map((p) => p[1])),
          ],
        ];

        console.log("bounds:", bounds);

        try {
          const { width, height } = window;
          const padding = 20;
          const viewport = new WebMercatorViewport({ width, height }).fitBounds(
            bounds,
            { padding }
          );

          console.log("Calculated viewport:", viewport);

          setViewState({
            longitude: viewport.longitude,
            latitude: viewport.latitude,
            zoom: viewport.zoom,
            bearing: 0,
            pitch: 0,
          });

          console.log("ViewState set successfully");
        } catch (err) {
          console.error("Error calculating viewport:", err);
          // Fallback to a simple average of the points
          const avgLon =
            points.reduce((sum, p) => sum + p[0], 0) / points.length;
          const avgLat =
            points.reduce((sum, p) => sum + p[1], 0) / points.length;
          setViewState({
            longitude: avgLon,
            latitude: avgLat,
            zoom: 3,
            bearing: 0,
            pitch: 0,
          });
        }
        console.log("Set fallback ViewState");
      }
    } catch (err) {
      setError(`Failed to fetch user data: ${err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  const fetchMapConfig = async () => {
    try {
      const response = await authApi.get("/config/map");
      setMapboxToken(response.data.mapbox_token);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchMapConfig();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = parseISO(timestamp);
    const relativeTime = formatDistanceToNow(date, { addSuffix: true });
    const exactTime = format(date, "yyyy-MM-dd HH:mm:ss");
    return { relativeTime, exactTime };
  };

  const handlePhotoClick = (photo) => {
    setZoomedPhoto(photo);
  };

  const handleCloseZoom = () => {
    setZoomedPhoto(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const layer = new ScatterplotLayer({
    id: "location-history",
    data: locationHistory,
    getPosition: (d) => [d.longitude, d.latitude],
    getColor: [255, 0, 0],
    getRadius: (d) => Math.min(d.radius, 1000),
    opacity: 0.5,
    pickable: true,
    radiusMinPixels: 3,
    radiusMaxPixels: 30,
  });

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar
              src={userInfo.photo_url}
              alt={userInfo.full_name}
              sx={{ width: 80, height: 80, mr: 2 }}
            />
            <Box>
              <Typography variant="h4" gutterBottom>
                {userInfo.full_name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                @{userInfo.username || "N/A"}
              </Typography>
            </Box>
          </Box>
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="User Info" />
            <Tab
              label={
                <Badge badgeContent={locationHistory.length} color="primary">
                  Location History
                </Badge>
              }
            />
            <Tab
              label={
                <Badge badgeContent={userChannels.length} color="primary">
                  Chats
                </Badge>
              }
            />
          </Tabs>
        </Paper>

        {activeTab === 0 && userInfo && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Personal Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box display="flex" alignItems="center" mb={1}>
                  <Person sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    <strong>User ID:</strong> {userInfo.user_id}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Phone sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    <strong>Phone:</strong> {userInfo.phone_number || "N/A"}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <AccessTime sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    <strong>Last Seen:</strong>{" "}
                    {userInfo.max_latest_seen_timestamp ? (
                      <Tooltip
                        title={
                          formatTimestamp(userInfo.max_latest_seen_timestamp)
                            .exactTime
                        }
                      >
                        <span>
                          {
                            formatTimestamp(userInfo.max_latest_seen_timestamp)
                              .relativeTime
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                </Box>
                {userInfo.is_bot && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <Android sx={{ mr: 1, color: "warning.main" }} />
                    <Typography variant="body1" color="warning.main">
                      <strong>Bot Account</strong>
                    </Typography>
                  </Box>
                )}
                {userInfo.is_fake && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <Warning sx={{ mr: 1, color: "error.main" }} />
                    <Typography variant="body1" color="error.main">
                      <strong>Fake Account</strong>
                    </Typography>
                  </Box>
                )}
                {userInfo.is_scam && (
                  <Box display="flex" alignItems="center" mb={1}>
                    <ReportProblem sx={{ mr: 1, color: "error.main" }} />
                    <Typography variant="body1" color="error.main">
                      <strong>Scam Account</strong>
                    </Typography>
                  </Box>
                )}
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>
                    <LocalOffer sx={{ mr: 1, verticalAlign: "middle" }} />
                    Tags:
                  </Typography>
                  {userTags.length > 0 ? (
                    <Box display="flex" flexWrap="wrap" gap={1.5}>
                      {userTags.map((tag) => (
                        <Chip
                          key={tag.id}
                          label={tag.name}
                          color="primary"
                          variant="outlined"
                          title={tag.description}
                          sx={{ fontSize: "1rem", height: "32px" }}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="body1  " color="textSecondary">
                      No tags assigned
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  User Photos
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={1}>
                  {photos.map((photo) => (
                    <Grid item key={photo.photo_id}>
                      <Tooltip
                        title={`Scraped: ${new Date(
                          photo.time_scraped
                        ).toLocaleString()}`}
                      >
                        <Card
                          sx={{ maxWidth: 120, cursor: "pointer" }}
                          onClick={() => handlePhotoClick(photo)}
                        >
                          <CardMedia
                            component="img"
                            height="100"
                            image={photo.photo_url}
                            alt={`User photo ${photo.photo_id}`}
                          />
                          <CardContent sx={{ p: 1 }}>
                            <Typography variant="caption">
                              ID: {photo.photo_id}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Location History
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ height: "500px", position: "relative" }}>
              <DeckGL
                initialViewState={viewState}
                controller={true}
                layers={[layer]}
              >
                <Map
                  mapboxAccessToken={mapboxToken}
                  mapStyle="mapbox://styles/mapbox/dark-v10"
                />
              </DeckGL>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  pointerEvents: "none",
                }}
              />
            </Box>
          </Paper>
        )}

        {activeTab === 2 && (
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Chats
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              {userChannels.map((channel) => (
                <Grid item xs={12} sm={6} md={4} key={channel.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{channel.title}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        @{channel.username || "N/A"}
                      </Typography>
                      {/* <Typography variant="body2">
                        Members: {channel.participants_count}
                      </Typography> */}
                      <Typography variant="body2">
                        Created:{" "}
                        {formatTimestamp(channel.date_created).relativeTime}
                      </Typography>
                      <Button
                        component={Link}
                        to={`/channel/${channel.id}`}
                        variant="outlined"
                        color="primary"
                        sx={{ mt: 1 }}
                      >
                        View Channel
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        )}
      </Box>
      <Modal
        open={zoomedPhoto !== null}
        onClose={handleCloseZoom}
        aria-labelledby="zoom-modal"
        aria-describedby="zoom-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
          onClick={handleCloseZoom}
        >
          {zoomedPhoto && (
            <img
              src={zoomedPhoto.photo_url}
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                objectFit: "contain",
                cursor: "pointer",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default UserPage;
