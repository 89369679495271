import React, { useEffect } from "react";
import { Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

const ContextMenuItems = ({
  onItemClick,
  featureSelected,
  setIsSearchOverlayOpen,
}) => {
  const polygonFunctionsDisabled = featureSelected === null;

  useEffect(() => {
    console.log("Rendering ContextMenuItems");
  });

  return (
    <>
      <MenuItem
        text="Search at Point"
        icon={IconNames.GEOSEARCH}
        onClick={() => onItemClick("dbSearchPoint")}
        intent="success"
        disabled={!polygonFunctionsDisabled}
      />
      {/* <MenuItem
        text="Search by User ID"
        icon={IconNames.SEARCH_TEXT}
        onClick={() => setIsSearchOverlayOpen(true)}
        intent="primary"
        disabled={!polygonFunctionsDisabled}
      /> */}

      {/* <MenuItem
        text="Live Search at Point"
        icon={IconNames.GEOSEARCH}
        onClick={() => onItemClick("liveSearch")}
        intent="warning"
        disabled={!polygonFunctionsDisabled || true}
      />
      <MenuItem
        text="Show Grid at Point"
        icon={IconNames.GRID}
        onClick={() => onItemClick("getGridPoints")}
        intent="primary"
        disabled={!polygonFunctionsDisabled}
      />
      <MenuItem
        text="Grid Search at Point"
        icon={IconNames.GRID_VIEW}
        onClick={() => onItemClick("gridSearch")}
        intent="success"
        disabled={!polygonFunctionsDisabled || true}
      />
      <MenuItem
        text="Request Polygon Grid"
        icon={IconNames.POLYGON_FILTER}
        onClick={() => onItemClick("gridLocationRequest")}
        intent="primary"
        disabled={polygonFunctionsDisabled || true}
      />
      <MenuItem
        text="Create Location Request"
        icon={IconNames.ADD_CLIP}
        onClick={() => onItemClick("createLocationRequest")}
        intent="primary"
        disabled={polygonFunctionsDisabled || true}
      /> */}
    </>
  );
};

export default ContextMenuItems;
