import React from "react";
import { Menu, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

const EditableFeatureContextMenu = ({ onItemClick, feature }) => {
  return (
    <Menu>
      {feature.geometry.type === "Polygon" && (
        <>
          <MenuItem
            text="Search Polygon"
            icon={IconNames.SEARCH_AROUND}
            onClick={() => onItemClick("searchPolygon", feature)}
            intent="success"
          />
          <MenuItem
            text="Advanced Search"
            icon={IconNames.SEARCH_TEMPLATE}
            onClick={() => onItemClick("advancedSearch", feature)}
            intent="primary"
          />
        </>
      )}
      <MenuItem
        text="Delete Feature"
        icon={IconNames.TRASH}
        onClick={() => onItemClick("deleteFeature", feature)}
        intent="danger"
      />
    </Menu>
  );
};

export default EditableFeatureContextMenu;
