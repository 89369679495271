// ObjectTable.js
import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  Tabs,
  Tab,
  Chip,
  TextField,
  Collapse,
} from "@mui/material";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import RotateLeft from "@mui/icons-material/RotateLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import NewUserInfoCard from "./NewUserInfoCard";
import { formatDistanceToNow } from "date-fns";

const ObjectTable = ({
  userData,
  userTags,
  isLoadingObjectTable,
  selectedUserIds,
  onZoomToUser,
  onClose,
  onSelectionChange,
  clearResults,
}) => {
  const [zoomedImage, setZoomedImage] = useState(null);
  const [zoomedImageIndex, setZoomedImageIndex] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [tagFilter, setTagFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const setZoomedImageWithIndex = (image, user, index) => {
    setZoomedImage({ image, user });
    setZoomedImageIndex(index);
  };

  useEffect(() => {
    console.log("Rendering Object Table");
  });

  const handleClose = () => {
    onClose();
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleZoomToUser = (userId) => {
    onZoomToUser(userId);
  };

  const handleSelectionChange = (userId) => {
    onSelectionChange(userId);
  };

  const handleUserChange = (direction) => {
    if (!zoomedImage) return;

    const currentIndex = displayedUserData.findIndex(
      (user) => user.user_id === zoomedImage.user.user_id
    );

    let newIndex;
    if (direction === "next") {
      newIndex = (currentIndex + 1) % displayedUserData.length;
    } else {
      newIndex =
        (currentIndex - 1 + displayedUserData.length) %
        displayedUserData.length;
    }

    const newUser = displayedUserData[newIndex];
    setZoomedImage({ image: newUser.photo_url, user: newUser });
  };

  const filterUsersByTag = useCallback(
    (users) => {
      if (!tagFilter) return users;
      return users.filter((user) => {
        const tags = userTags[user.user_id] || [];
        return tags.some((tag) =>
          tag.name.toLowerCase().includes(tagFilter.toLowerCase())
        );
      });
    },
    [tagFilter, userTags]
  );

  const displayedUserData = filterUsersByTag(
    activeTab === 0
      ? userData
      : userData.filter((user) => selectedUserIds.includes(user.user_id))
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!zoomedImage) return;

      if (event.key === "ArrowDown") {
        event.preventDefault();
        const nextIndex = (zoomedImageIndex + 1) % displayedUserData.length;
        const nextUser = displayedUserData[nextIndex];
        setZoomedImageWithIndex(nextUser.photo_url, nextUser, nextIndex);
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        const prevIndex =
          (zoomedImageIndex - 1 + displayedUserData.length) %
          displayedUserData.length;
        const prevUser = displayedUserData[prevIndex];
        setZoomedImageWithIndex(prevUser.photo_url, prevUser, prevIndex);
      }
    },
    [zoomedImage, zoomedImageIndex, displayedUserData, setZoomedImageWithIndex]
  );

  useEffect(() => {
    if (zoomedImage) {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [zoomedImage, handleKeyDown]);

  const columns = [
    {
      field: "Add User",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Button
          minimal
          icon={
            selectedUserIds.includes(params.row.user_id)
              ? IconNames.TICK_CIRCLE
              : IconNames.ADD
          }
          intent={
            selectedUserIds.includes(params.row.user_id) ? "success" : "none"
          }
          onClick={() => handleSelectionChange(params.row.user_id)}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "Locate User",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Tooltip title="Locate User">
          <span>
            <Button
              minimal
              icon={IconNames.LOCATE}
              disabled={!selectedUserIds.includes(params.row.user_id)}
              onClick={() => handleZoomToUser(params.row.user_id)}
              intent="primary"
            ></Button>
          </span>
        </Tooltip>
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "photo_url",
      headerName: "Photo",
      width: 75,
      renderCell: (params) => {
        const rowIndex = displayedUserData.findIndex(
          (user) => user.user_id === params.row.user_id
        );
        return (
          <img
            src={params.value}
            alt={params.row.name}
            style={{
              width: "90px",
              height: "90px",
              objectFit: "cover",
              cursor: "pointer",
              borderRadius: "2px",
            }}
            onClick={() =>
              setZoomedImageWithIndex(params.value, params.row, rowIndex)
            }
          />
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 150,
      renderCell: (params) => {
        const tags = userTags[params.row.user_id] || [];
        return (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {tags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                size="small"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "white",
                }}
                title={tag.description}
              />
            ))}
          </div>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "max_latest_seen_timestamp",
      headerName: "Last Seen",
      width: 150,
      valueFormatter: (params) => {
        if (params.value) {
          const timestamp = new Date(params.value);
          return formatDistanceToNow(timestamp, { addSuffix: true });
        }
        return "";
      },
    },
    { field: "full_name", headerName: "Name", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "phone_number", headerName: "Phone Number", width: 200 },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Paper
      sx={{
        // backgroundColor: "transparent",
        backgroundColor: "rgba(30, 30, 30, 0.9)", // Adjust the alpha value here
        color: "white",
        borderRadius: "8px",
        height: "calc(100% - 16px)",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.25)",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginBottom: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px",
        }}
      >
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="All Users" />
          <Tab label="Selected Users" />
        </Tabs>
        <Box>
          <Tooltip title="Toggle Filters">
            <IconButton
              onClick={toggleFilters}
              sx={{
                color: "white",
                mr: 1,
                backgroundColor: showFilters
                  ? "rgba(255, 255, 255, 0.2)"
                  : "transparent",
                "&:hover": {
                  backgroundColor: showFilters
                    ? "rgba(255, 255, 255, 0.3)"
                    : "rgba(255, 255, 255, 0.1)",
                },
                transition: "background-color 0.3s",
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear Unselected Results">
            <IconButton
              onClick={() => {
                clearResults();
              }}
              sx={{
                color: "white",
                mr: 1,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
                transition: "background-color 0.3s",
              }}
            >
              <RotateLeft />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close Results Table">
            <IconButton
              onClick={handleClose}
              sx={{
                color: "white",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
                transition: "background-color 0.3s",
              }}
            >
              <CloseFullscreenRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={showFilters}>
        <Box sx={{ padding: "8px" }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Filter by tag"
            value={tagFilter}
            onChange={(e) => setTagFilter(e.target.value)}
            sx={{
              input: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.23)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              mb: 1,
            }}
          />
        </Box>
      </Collapse>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: showFilters ? "calc(100% - 130px)" : "calc(100% - 48px)",
          transition: "height 0.3s ease-in-out",
        }}
      >
        <DataGrid
          rows={displayedUserData}
          columns={columns}
          getRowId={(row) => row.user_id}
          rowHeight={75}
          loading={isLoadingObjectTable}
          components={{
            Toolbar: showFilters ? GridToolbar : null,
          }}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "rgb(36, 39, 48)",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            },
            "& .MuiDataGrid-row": {
              color: "white",
            },
            "& .MuiTablePagination-root": {
              color: "white",
            },
          }}
        />
      </Box>
      {zoomedImage && (
        <NewUserInfoCard
          open={Boolean(zoomedImage)}
          onClose={() => {
            setZoomedImage(null);
            setZoomedImageIndex(null);
          }}
          user={zoomedImage.user}
          onUserChange={handleUserChange}
          userTags={userTags[zoomedImage.user.user_id] || []}
        />
      )}
    </Paper>
  );
};

export default ObjectTable;
