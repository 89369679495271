import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormGroup,
  InputGroup,
  Callout,
  Intent,
  Card,
  Elevation,
} from "@blueprintjs/core";
import { Typography, Container, Box } from "@mui/material";
import axios from "axios";

// contexts
import { useAuth, useUserInfo } from "../contexts/AuthContext";
import { ToasterContext } from "../contexts/ToasterContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toaster = useContext(ToasterContext);
  const { login } = useAuth();
  const userInfo = useUserInfo();

  console.log("userInfo is:", userInfo);

  useEffect(() => {
    axios
      .get("/auth/registration_status")
      .then((response) =>
        setIsRegisterDisabled(response.data.registration_disabled)
      )
      .catch((error) => {
        console.error("Error fetching registration status:", error);
        setIsRegisterDisabled(true);
        toaster.current?.show({
          message: "Error fetching registration status",
          intent: Intent.DANGER,
          icon: "error",
        });
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        isLogin ? "/auth/login" : "/auth/register",
        {
          username,
          email: isLogin ? undefined : email,
          password,
        }
      );
      login(response.data.access_token, response.data.refresh_token);
      navigate("/globe");
    } catch (error) {
      console.log("Error is:", error);
      if (error.response) {
        setError(`Something went wrong: ${error.response.data.msg}`);
      } else {
        setError("Something went wrong");
      }
      toaster.current?.show({
        message: "Something went wrong",
        intent: Intent.DANGER,
        icon: "error",
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Card elevation={Elevation.TWO} className="bp4-dark">
          <Box p={4}>
            <Typography variant="h4" align="center" gutterBottom>
              {isLogin ? "Login" : "Register"}
            </Typography>
            {error && <Callout intent={Intent.DANGER}>{error}</Callout>}
            {!isLogin && isRegisterDisabled && (
              <Callout intent={Intent.WARNING}>
                Registration coming soon!
              </Callout>
            )}
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </FormGroup>
              {!isLogin && (
                <FormGroup>
                  <InputGroup
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={isRegisterDisabled}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <InputGroup
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <Button type="submit" intent={Intent.PRIMARY} fill>
                {isLogin ? "Login" : "Register"}
              </Button>
            </form>
            <Box mt={2} textAlign="center">
              <Button
                minimal
                onClick={() => setIsLogin(!isLogin)}
                disabled={isRegisterDisabled}
              >
                {isLogin
                  ? "Don't have an account? Register"
                  : "Already have an account? Login"}
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginPage;
