import React, { useState, useEffect } from "react";
import {
  Dialog,
  Classes,
  Button,
  Intent,
  MenuItem,
  Icon,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { Stepper, Step, StepLabel, StepContent, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Classes as BlueprintClasses } from "@blueprintjs/core";
import authApi from "../api/authApi.js";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const AdvancedSearchModal = ({ isOpen, onClose, onSubmit, geometry }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchTags();
      setActiveStep(1);
      setSelectedTags([]);
    }
  }, [isOpen]);

  const fetchTags = async () => {
    try {
      const response = await authApi.get("/api/tagging/tags");
      setAvailableTags(response.data);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const handleTagSelect = (tag) => {
    setSelectedTags((prevSelectedTags) => {
      const tagIndex = prevSelectedTags.findIndex((t) => t.id === tag.id);
      if (tagIndex === -1) {
        return [...prevSelectedTags, tag];
      } else {
        return prevSelectedTags.filter((t) => t.id !== tag.id);
      }
    });
  };

  const handleTagRemove = (tagToRemove, tagIndex) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.filter((tag, index) => index !== tagIndex)
    );
  };

  const handleSubmit = () => {
    onSubmit(
      geometry,
      selectedTags.map((tag) => tag.id)
    );
    onClose();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ["Select Geometry", "Select Tags", "Run Search"];

  const renderTag = (tag) => tag.name;

  const renderTagMenuItem = (tag, { handleClick, modifiers }) => {
    const isSelected = selectedTags.some((t) => t.id === tag.id);
    return (
      <MenuItem
        key={tag.id}
        icon={isSelected ? <Icon icon="tick" /> : "blank"}
        text={tag.name}
        onClick={handleClick}
        active={modifiers.active}
      />
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Advanced Search"
      className={BlueprintClasses.DARK}
    >
      <ThemeProvider theme={darkTheme}>
        <div className={Classes.DIALOG_BODY}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    {index === 0 && <div>Geometry selected</div>}
                    {index === 1 && (
                      <MultiSelect
                        items={availableTags}
                        selectedItems={selectedTags}
                        onItemSelect={handleTagSelect}
                        itemRenderer={renderTagMenuItem}
                        tagRenderer={renderTag}
                        popoverProps={{ minimal: true }}
                        fill={true}
                        tagInputProps={{
                          onRemove: handleTagRemove,
                          tagProps: { minimal: true },
                        }}
                      />
                    )}
                    {index === 2 && (
                      <Button intent={Intent.PRIMARY} onClick={handleSubmit}>
                        Run Search
                      </Button>
                    )}
                    <Box sx={{ mb: 2, mt: 2 }}>
                      <div>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          style={{ marginRight: 8 }}
                        >
                          Back
                        </Button>
                        <Button
                          intent={Intent.PRIMARY}
                          onClick={
                            index === steps.length - 1
                              ? handleSubmit
                              : handleNext
                          }
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                      </div>
                    </Box>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </ThemeProvider>
    </Dialog>
  );
};

export default AdvancedSearchModal;
