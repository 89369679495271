// File: /mnt/raid0/osint/talos/frontend/src/components/Protected.js

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAuth, useUserInfo } from "../contexts/AuthContext";

const Protected = ({ children, redirectTo }) => {
  const { user, isLoading: authLoading } = useAuth();
  const { userInfo, isLoading: userInfoLoading } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authLoading && !userInfoLoading) {
      if (!user) {
        navigate(redirectTo || "/login", {
          state: { from: location },
          replace: true,
        });
      } else if (userInfo && !userInfo.is_active) {
        navigate("/globe", { replace: true });
      }
    }
  }, [
    user,
    userInfo,
    authLoading,
    userInfoLoading,
    navigate,
    redirectTo,
    location,
  ]);

  if (authLoading || userInfoLoading) {
    return <div>Loading...</div>; // Or a loading spinner component
  }

  if (!user || (userInfo && !userInfo.is_active)) {
    return null;
  }

  return children;
};

export default Protected;
