import React, { useState, useEffect } from "react";
import Globe from "react-globe.gl";
import authApi from "../api/authApi";
import { Box, Typography, Container, TextField, Button } from "@mui/material";

const HomePage = () => {
  const [cablePaths, setCablePaths] = useState([]);

  useEffect(() => {
    authApi.get("/api/submarine_cables").then(({ data }) => {
      const cablePaths = data.features.flatMap(({ geometry, properties }) =>
        geometry.coordinates.map((coords) => ({ coords, properties }))
      );
      setCablePaths(cablePaths);
    });
  }, []);

  return (
    <Box
      sx={{ position: "relative", height: "90vh", backgroundColor: "black" }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          py: 4,
          color: "white",
        }}
      >
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            position: "relative",
            zIndex: 2,
            color: "white",
            // fontFamily: "'DM Mono', monospace",
            // fontSize: "2rem",
            // textShadow:
            //   "0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4)",
          }}
        >
          {/* Threat Intelligence */}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "60%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Globe
            globeImageUrl="//unpkg.com/three-globe/example/img/earth-dark.jpg"
            bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
            backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
            width="100%"
            height="100%"
            pathsData={cablePaths}
            pathPoints="coords"
            pathPointLat={(p) => p[1]}
            pathPointLng={(p) => p[0]}
            pathColor={(path) => path.properties.color}
            pathLabel={(path) => path.properties.name}
            pathDashLength={0.1}
            pathDashGap={0.008}
            pathDashAnimateTime={12000}
            initialCoordinates={[0, 51.5]}
            initialZoom={3}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            component="a"
            href="mailto:max@talostechnologies.co.uk"
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
            }}
          >
            Get In Contact
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default HomePage;
