import React from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider,
  Button,
  Alignment,
  Classes,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useAuth, useUserInfo } from "../contexts/AuthContext";

// styles
const navbarStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: "rgb(36, 39, 48)",
};

const navbarGroupStyle = {
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "space-between",
};

const buttonStyle = {
  margin: "0 5px",
};

const AppNavbar = () => {
  const { user, logout, isLoading: authLoading } = useAuth();
  const { userInfo, isLoading: userInfoLoading } = useUserInfo();

  // Show a loading spinner while auth or user info is loading
  if (authLoading || userInfoLoading) {
    return (
      <Navbar style={navbarStyle} fixedToTop={true}>
        <NavbarGroup>
          <Spinner size={Spinner.SIZE_SMALL} />
        </NavbarGroup>
      </Navbar>
    );
  }

  return (
    <Navbar style={navbarStyle} fixedToTop={true}>
      <NavbarGroup style={navbarGroupStyle}>
        <NavbarHeading>Talos</NavbarHeading>
        <NavbarDivider />
        <Link to="/globe">
          <Button className={Classes.MINIMAL} icon="home" text="Home" />
        </Link>
        <Link to="/app">
          <Button className={Classes.MINIMAL} icon="globe" text="App" />
        </Link>
        {user && userInfo?.is_admin && (
          <Link to="/admin/location-requests">
            <Button
              className={Classes.MINIMAL}
              icon={IconNames.MAP}
              text="Tasking Requests"
            />
          </Link>
        )}
        {user && userInfo?.is_admin && (
          <Link to="/admin/create-location-request">
            <Button
              className={Classes.MINIMAL}
              icon={IconNames.ADD_CLIP}
              text="Task New Location"
            />
          </Link>
        )}
        {user && userInfo?.is_admin && (
          <Link to="/admin/telegram-sessions">
            <Button
              className={Classes.MINIMAL}
              icon={IconNames.MOBILE_PHONE}
              text="Telegram Sessions"
            />
          </Link>
        )}
        {user && userInfo?.is_admin && (
          <Link to="/admin/channel-crawl">
            <Button
              className={Classes.MINIMAL}
              icon={IconNames.DIAGRAM_TREE}
              text="Channel Crawler"
            />
          </Link>
        )}
        {user && userInfo?.is_admin && (
          <Link to="/grafana/d/adjctpw2v5udcd/collection-metric-dashboard">
            <Button
              className={Classes.MINIMAL}
              icon={IconNames.DASHBOARD}
              text="Collection Coverage"
            />
          </Link>
        )}
        {user && userInfo?.is_admin && (
          <Link to="/grafana/d/bdmzo3kkzjk74a/cron-job-monitoring">
            <Button
              className={Classes.MINIMAL}
              icon={IconNames.TIME}
              text="Status Monitoring"
            />
          </Link>
        )}
        {user && userInfo?.is_admin && (
          <Link to="/testing">
            <Button
              // style={buttonStyle}
              className={Classes.MINIMAL}
              icon={IconNames.LAB_TEST}
              text="Experimental"
            />
          </Link>
        )}
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
        {user ? (
          <Button
            style={buttonStyle}
            className={Classes.MINIMAL}
            text="Logout"
            onClick={logout}
            icon="log-out"
          />
        ) : (
          <Link to="/login">
            <Button
              style={buttonStyle}
              className={Classes.MINIMAL}
              intent="success"
              text="Login"
              icon="log-in"
            />
          </Link>
        )}
      </NavbarGroup>
    </Navbar>
  );
};

export default AppNavbar;
