import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Elevation,
  Menu,
  MenuItem,
  Popover,
  Position,
  Switch,
  Classes,
  Intent,
} from "@blueprintjs/core";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Grid,
  Skeleton,
} from "@mui/material";
import authApi from "../api/authApi";
import { ToasterContext } from "../contexts/ToasterContext";

const AdminLocationRequestsPage = () => {
  const [loadingRequests, setLoadingRequests] = useState({});
  const [inRotationRequests, setInRotationRequests] = useState([]);
  const [notInRotationRequests, setNotInRotationRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toaster = useContext(ToasterContext);

  useEffect(() => {
    fetchLocationRequests();
  }, []);

  const fetchLocationRequests = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await authApi.get("/api/location_request/retrieve_all");

      if (!response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid response format");
      }

      const inRotation = response.data.filter((req) => req.in_rotation);
      const notInRotation = response.data.filter((req) => !req.in_rotation);

      setInRotationRequests(inRotation);
      setNotInRotationRequests(notInRotation);
    } catch (error) {
      console.error("Error fetching location requests:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const startCollection = async (locationRequestId) => {
    setLoadingRequests((prevState) => ({
      ...prevState,
      [locationRequestId]: true,
    }));
    try {
      await authApi.post(
        `/api/location_request/start_collection?location_request_id=${locationRequestId}`
      );
      fetchLocationRequests();
      toaster.current.show({
        message: "Collection started successfully",
        intent: "success",
      });
    } catch (error) {
      console.error("Error starting collection:", error);
      toaster.current.show({
        message: `Error starting collection: ${error.message}`,
        intent: "danger",
      });
    } finally {
      setLoadingRequests((prevState) => ({
        ...prevState,
        [locationRequestId]: false,
      }));
    }
  };

  const stopCollection = async () => {
    try {
      await authApi.post("/api/location_request/stop_collection");
      fetchLocationRequests();
      toaster.current.show({
        message: "All collections stopped",
        intent: "success",
      });
    } catch (error) {
      console.error("Error stopping collection:", error);
      toaster.current.show({
        message: "Error stopping collections",
        intent: "danger",
      });
    }
  };

  const deleteLocationRequest = async (locationRequestId) => {
    try {
      await authApi.delete(`/api/location_request/delete/${locationRequestId}`);
      fetchLocationRequests();
      toaster.current.show({
        message: "Location request deleted successfully",
        intent: "success",
      });
    } catch (error) {
      console.error("Error deleting location request:", error);
      toaster.current.show({
        message: "Error deleting location request",
        intent: "danger",
      });
    }
  };

  const toggleRotation = async (locationRequestId) => {
    try {
      await authApi.post(
        `/api/location_request/toggle_rotation/${locationRequestId}`
      );
      fetchLocationRequests();
      toaster.current.show({
        message: "Rotation status updated successfully",
        intent: "success",
      });
    } catch (error) {
      console.error("Error updating rotation status:", error);
      toaster.current.show({
        message: "Error updating rotation status",
        intent: "danger",
      });
    }
  };

  const renderActionMenu = (request) => (
    <Popover
      content={
        <Menu>
          <MenuItem
            icon="play"
            text={loadingRequests[request.id] ? "Loading..." : "Start"}
            onClick={() => startCollection(request.id)}
            disabled={request.active}
          />
          <MenuItem
            icon="trash"
            text="Delete"
            onClick={() => deleteLocationRequest(request.id)}
          />
          <MenuItem
            icon={request.in_rotation ? "circle" : "circle-arrow-right"}
            text={
              request.in_rotation ? "Remove from Rotation" : "Add to Rotation"
            }
            onClick={() => toggleRotation(request.id)}
          />
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
    >
      <Button icon="more" />
    </Popover>
  );

  const renderLocationRequestCard = (request) => (
    <Card
      key={request.id}
      elevation={Elevation.TWO}
      style={{ marginBottom: "10px" }}
    >
      <h3>{request.request_name}</h3>
      <p>ID: {request.id}</p>
      <p>User ID: {request.user_id}</p>
      <p>Grid Size: {request.grid_size_meters} meters</p>
      <p>Created At: {request.created_at}</p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Switch checked={request.active} disabled label="Active" />
        {renderActionMenu(request)}
      </div>
    </Card>
  );

  const renderSkeletonCard = () => (
    <Card elevation={Elevation.TWO} style={{ marginBottom: "10px" }}>
      <Skeleton>
        <Typography variant="h5">Loading request name...</Typography>
      </Skeleton>
      <Skeleton>
        <Typography>Loading ID...</Typography>
      </Skeleton>
      <Skeleton>
        <Typography>Loading User ID...</Typography>
      </Skeleton>
      <Skeleton>
        <Typography>Loading Grid Size...</Typography>
      </Skeleton>
      <Skeleton>
        <Typography>Loading Created At...</Typography>
      </Skeleton>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Skeleton width={100}>
          <Switch disabled />
        </Skeleton>
        <Skeleton width={40}>
          <Button />
        </Skeleton>
      </Box>
    </Card>
  );

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Location Requests
      </Typography>

      <Box display="flex" justifyContent="center" mb={2}>
        <Button
          icon="stop"
          intent="danger"
          onClick={stopCollection}
          style={{ width: 200 }}
        >
          Stop All
        </Button>
      </Box>

      <Typography variant="h5" gutterBottom>
        In Rotation
      </Typography>
      <Grid container spacing={2}>
        {loading ? (
          [...Array(3)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {renderSkeletonCard()}
            </Grid>
          ))
        ) : error ? (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        ) : inRotationRequests.length > 0 ? (
          inRotationRequests.map((request) => (
            <Grid item xs={12} sm={6} md={4} key={request.id}>
              {renderLocationRequestCard(request)}
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>No requests in rotation</Typography>
          </Grid>
        )}
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Not In Rotation
      </Typography>
      <TableContainer component={Paper} className={Classes.DARK}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Request Name</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Grid Size (meters)</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              [...Array(3)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton>
                      <Typography>Loading ID...</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton>
                      <Typography>Loading Name...</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton>
                      <Typography>Loading User ID...</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton>
                      <Typography>Loading Grid Size...</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton>
                      <Typography>Loading Date...</Typography>
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton>
                      <Switch disabled />
                    </Skeleton>
                  </TableCell>
                  <TableCell>
                    <Skeleton>
                      <Button />
                    </Skeleton>
                  </TableCell>
                </TableRow>
              ))
            ) : error ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : notInRotationRequests.length > 0 ? (
              notInRotationRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.id}</TableCell>
                  <TableCell>{request.request_name}</TableCell>
                  <TableCell>{request.user_id}</TableCell>
                  <TableCell>{request.grid_size_meters}</TableCell>
                  <TableCell>{request.created_at}</TableCell>
                  <TableCell>
                    <Switch checked={request.active} disabled />
                  </TableCell>
                  <TableCell>{renderActionMenu(request)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography>No requests outside rotation</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminLocationRequestsPage;
